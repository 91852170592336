import {BaseEntity} from '../models/base-entity.model';
import {DescriptionText} from '../models/description-text.model';
import {Files} from '../models/files.model';
import {Group} from '../models/group.model';
import {LoopGroup} from '../models/loop-group.model';
import {QuestionBase} from '../models/question-base.model';
import {Table} from '../models/table';
import {TextboxQuestion} from '../models/textbox-question.model';
import {MultiCheckBoxQuestion} from '../models/multi-check-box-question.model';
import {RadioButtonQuestion} from '../models/radio-button-question.model';
import {DropdownQuestion} from '../models/dropdown-question.model';
import {SliderQuestion} from '../models/slider-question.model';
import {TextAreaQuestion} from '../models/text-area-question.model';


export class ComponentHelper {

  static getQuestion(entity: BaseEntity) {
    return entity as QuestionBase<string>;
  }

  static getGroup(entity: BaseEntity) {
    return entity as Group;
  }

  static getLoopGroup(entity: BaseEntity) {
    return entity as LoopGroup;
  }

  static getDescriptionText(entity: BaseEntity) {
    return entity as DescriptionText;
  }

  static getTable(entity: BaseEntity) {
    return entity as Table;
  }

  static getFiles(entity: BaseEntity) {
    return entity as Files;
  }

  static getType(entity: BaseEntity) {
    return (entity as any).type;
  }

  static isQuestion(entity: BaseEntity): boolean {
    return entity instanceof QuestionBase ||
           entity instanceof RadioButtonQuestion ||
           entity instanceof DropdownQuestion ||
           entity instanceof MultiCheckBoxQuestion ||
           entity instanceof SliderQuestion ||
           entity instanceof TextAreaQuestion ||
           entity instanceof TextboxQuestion;
  }

  static isTextboxQuestion(entity: BaseEntity | null): boolean {
    return entity instanceof TextboxQuestion;
  }

  static isMultiCheckboxQuestion(entity: BaseEntity): boolean {
    return entity instanceof MultiCheckBoxQuestion;
  }

  static isRadioButtonQuestion(entity: BaseEntity): boolean {
    return entity instanceof RadioButtonQuestion;
  }

  static isGroup(entity: BaseEntity): boolean {
    return entity instanceof Group && !entity.repeatable;
  }

  static isLoopGroup(entity: BaseEntity): boolean {
    return entity instanceof LoopGroup;
  }

  static isDescriptionText(entity: BaseEntity): boolean {
    return entity instanceof DescriptionText;
  }

  static isTable(entity: BaseEntity): boolean {
    return entity instanceof Table;
  }

  static isFiles(entity: BaseEntity): boolean {
    return entity instanceof Files;
  }

  static isRepeatable(entity: BaseEntity): boolean {
    return (entity as any).repeatable;
  }

  static isRequired(entity: BaseEntity): boolean {
    return (entity as any).required;
  }

  static isHidden(entity: BaseEntity): boolean {
    return entity.hidden || entity.isHidden;
  }
}
