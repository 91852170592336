import {Answer} from './answer.model';
import {IDynamicAnswerOptions} from './interfaces/idynamic-answer-options.model';

export class DynamicAnswer extends Answer {
  reference: any;

  constructor(options: IDynamicAnswerOptions) {
    super(options);
    this.reference = options.reference || undefined;
  }
}
