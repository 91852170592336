import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckboxAnswer} from '../../../../models/checkbox-answer.model';
import {QuestionBase} from '../../../../models/question-base.model';

@Component({
  selector: 'app-checkbox-subanswers',
  templateUrl: './checkbox-subanswers.component.html',
  styleUrls: ['./checkbox-subanswers.component.css']
})
export class CheckboxSubanswersComponent implements OnInit {

  @Input() subanswers: CheckboxAnswer[] = [];
  @Input() question: QuestionBase<any> | null = null;
  @Input() parentAnswer: CheckboxAnswer | null = null;
  @Input() readOnly: boolean = false;

  @Output() updateSubanswerToParent = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  updateAnswers(name: string): CheckboxAnswer[] {
    if (!this.question) {
      throw new Error('Question not found');
    }
    if (this.question.value !== undefined) {
      for (const subanswer of this.subanswers) {
        if (subanswer.name === name) {
          subanswer.checked = subanswer.checked === false;
        }
      }
    }
    this.updateSubanswerToParent.emit(this.subanswers);
    return this.subanswers;
  }

  log(event) {
    console.log("DEVELOPMENT LOG: ", event)
  }
}
