import {Group} from './group.model';
import {IGroupOptions} from './interfaces/igroup-options.model';

export class Table extends Group {
  isTable = true;

  constructor(options: IGroupOptions) {
    super(options);
  }
}
