import {Injectable} from '@angular/core';
import {Names} from '../models/names';
import {MultiCheckBoxQuestion} from '../models/multi-check-box-question.model';
import {RadioButtonQuestion} from '../models/radio-button-question.model';
import {DescriptionText} from '../models/description-text.model';
import {Form} from '../models/form.model';

@Injectable({
  providedIn: 'root'
})
export class ConditionService {

  // Constants
  public static TOKEN_COMPARATION_ANSWER: string = Names.TOKEN_COMPARATION_ANSWER;
  public static TOKEN_IN: string = Names.TOKEN_IN;
  public static TOKEN_COMPARATION_VALUE: string = Names.TOKEN_COMPARATION_VALUE;
  public static TOKEN_BETWEEN: string = Names.TOKEN_BETWEEN;
  public static TOKEN: string = Names.TOKEN;
  public static TOKEN_EMPTY: string = Names.TOKEN_EMPTY;

  formRunner: any;

  constructor() {
  }

  checkCondition(form: Form, question, conditionFlow) {
    this.formRunner = form;
    return this.fulfillsCondition(question, conditionFlow);
  }

  fulfillsCondition(question, conditionFlow): boolean {
    const booleanArray = this.buildBooleanArray(conditionFlow);
    const booleanString = this.stringifyBooleanArray(booleanArray);
    if (question.value === undefined && !(question instanceof DescriptionText)) {
      return false;
    }
    // return eval(booleanString); Eval not allowed!
    return Function("return " + booleanString)();
  }


  buildBooleanArray(flowObject) {
    const booleanArray = [];
    for (const condition of flowObject.condition) {
      if (condition.class === ConditionService.TOKEN_COMPARATION_ANSWER) {
        const question = this.findQuestionWithPath(condition.question_id);
        booleanArray.push(this.checkTokenComparationAnswer(condition, question));
      } else if (condition.class === ConditionService.TOKEN_IN) {
        const expectedValues = this.buildTokenIn(condition.values);
        const question = this.findQuestionWithPath(condition.question_id);
        booleanArray.push(this.checkTokenIn(expectedValues, question));
      } else if (condition.class === ConditionService.TOKEN_COMPARATION_VALUE) {
        const question = this.findQuestionWithPath(condition.question_id);
        booleanArray.push(this.checkTokenComparationValue(condition, question));
      } else if (condition.class === ConditionService.TOKEN_BETWEEN) {
        const question = this.findQuestionWithPath(condition.question_id);
        booleanArray.push(this.checkTokenBetween(condition, question));
      } else if (condition.class === ConditionService.TOKEN_EMPTY) {
        const question = this.findQuestionWithPath(condition.question_id);
        booleanArray.push(this.checkTokenEmpty(condition, question));
      } else if (condition.class === ConditionService.TOKEN) {
        if (condition.type !== 'RETURN') {
          booleanArray.push(condition.type);
        }
      }
    }
    return booleanArray;
  }

  buildTokenIn(values) {
    const answers = [];
    for (const value of values) {
      answers.push(value.answer_id[value.answer_id.length - 1]);
    }
    return answers;
  }

  findQuestionWithPath(path) {
    for (const children of this.formRunner.children) {
      if (children.name === path[0]) {
        for (const childrenPath of children.childrenPaths) {
          if (childrenPath.path.toString() === path.toString()) {
            return childrenPath;
          }
        }
      }
    }
  }

  checkTokenComparationValue(condition, question) {
    let expectedValue = condition.value;
    if (!isNaN(expectedValue)) {
      expectedValue = parseFloat(condition.value);
    }
    if (!question || !question.value) {
      return false;
    }
    let currentValue = question.value;
    if (condition.subformat === 'DATE_PERIOD') {
      if (condition.datePeriodUnit === 'YEAR') {
        const date = new Date(question.value);
        const actualDate = new Date();
        const resultInMilliseconds = actualDate.getTime() - date.getTime();
        const result = resultInMilliseconds / 1000 / 60 / 60 / 24 / 365;
        currentValue = Math.floor(result);
      } else if (condition.datePeriodUnit === 'MONTH') {
        const date = new Date(question.value);
        const actualDate = new Date();
        const resultInMilliseconds = actualDate.getTime() - date.getTime();
        const result = resultInMilliseconds / 1000 / 60 / 60 / 24 / 30;
        currentValue = Math.floor(result);
      } else if (condition.datePeriodUnit === 'DAY') {
        const date = new Date(question.value);
        const actualDate = new Date();
        const resultInMilliseconds = actualDate.getTime() - date.getTime();
        const result = resultInMilliseconds / 1000 / 60 / 60 / 24;
        currentValue = Math.floor(result);
      }
    }
    if (!isNaN(currentValue)) {
      currentValue = parseFloat(currentValue);
    }
    if (currentValue === '') {
      return false;
    }
    if (condition.type === 'GT') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue > expectedValue;
    } else if (condition.type === 'GE') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue >= expectedValue;
    } else if (condition.type === 'LT') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue < expectedValue;
    } else if (condition.type === 'LE') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue <= expectedValue;
    } else if (condition.type === 'EQ') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue === expectedValue;
    } else if (condition.type === 'NE') {
      if (currentValue === undefined) {
        return false;
      }
      return currentValue !== expectedValue;
    }
    return false;
  }

  checkTokenComparationAnswer(condition: any, question: any): boolean {
    const expectedValue = condition.answer_id[condition.answer_id.length - 1];
    if (!question || question.value === undefined) {
      return false;
    }
    let currentValue = question.value;
    if (question instanceof RadioButtonQuestion) {
      currentValue = question.realValue;
    }
    if (question instanceof MultiCheckBoxQuestion) {
      if (condition.type === 'EQ') {
        for (const values of question.value) {
          if (values.name === expectedValue && values.checked) {
            return true;
          }
          if (values.children.length > 0) {
            for (const children of values.children) {
              if (children.checked && children.name === expectedValue) {
                return true;
              }
            }
          }
        }
        return false;
      } else if (condition.type === 'NE') {
        for (const value of question.value) {
          if (value.name === expectedValue && value.checked) {
            return false;
          }
          if (value.children.length > 0) {
            for (const children of value.children) {
              if (children.checked && children.name === expectedValue) {
                return false;
              }
            }
          }
        }
        return true;
      }
    } else {
      if (condition.type === 'EQ') {
        return currentValue === expectedValue;
      } else if (condition.type === 'NE') {
        return currentValue !== expectedValue;
      }
    }
    return false;
  }

  checkTokenBetween(condition, question): boolean {
    if (!condition || !question) {
      return false;
    }
    const valueStart = condition.valueStart;
    const valueEnd = condition.valueEnd;
    const currentValue = question.value;
    if (condition.type === 'BETWEEN') {
      if (currentValue === undefined || currentValue === '') {
        return false;
      }
      return valueStart <= currentValue && currentValue <= valueEnd;
    }
    return false;
  }

  checkTokenEmpty(condition, question) {
    if (!condition || !question) {
      return true;
    }
    if (condition.type === 'EMPTY') {
      if (question.value === undefined) {
        return true;
      }
      const trimmedValue = question.value.trim();
      return question.value === '' || question.value === undefined || trimmedValue === '';
    } else {
      return true;
    }
  }

  checkTokenIn(tokenInValues, question): boolean {
    if (!question || question.value === undefined) {
      return false;
    }
    if (question instanceof MultiCheckBoxQuestion) {
      for (const tokenValue of tokenInValues) {
        for (const questionValue of question.value) {
          if (tokenValue === questionValue.name && questionValue.checked) {
            return true;
          }
          if (questionValue.children.length > 0) {
            for (const children of questionValue.children) {
              if (children.checked && children.name === tokenValue) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } else {
      let expectedValue = question.value;
      if (question instanceof RadioButtonQuestion) {
        expectedValue = question.realValue;
      }
      for (const tokenInValue of tokenInValues) {
        if (tokenInValue === expectedValue) {
          return true;
        }
      }
      return false;
    }
  }

  stringifyBooleanArray(array): string {
    let result = '';
    for (const values of array) {
      if (values === 'NOT') {
        result = result.concat('!');
      } else if (values === 'LEFT_PAR') {
        result = result.concat('(');
      } else if (values === 'RIGHT_PAR') {
        result = result.concat(')');
      } else if (values === 'AND') {
        result = result.concat(' && ');
      } else if (values === 'OR') {
        result = result.concat(' || ');
      } else {
        result = result.concat(values);
      }
    }
    return result;
  }
}
