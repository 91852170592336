import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LogService} from './log.service';
import {Form} from '../models/form.model';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  answers: any;

  formPath = './assets/';
  defaultForm = 'PersonalDetails';
  logs = false;


  constructor(private http: HttpClient, private logger: LogService) {
  }

  public getForm(formName : string | undefined = this.defaultForm): Observable<any> {
    return this.http.get(this.formPath + 'forms/' + formName + '.json').pipe((res) => {
      if (this.logs) {
        this.logger.info('response obtained:', res);
        this.logger.info('Getting from:');
        this.logger.info(this.formPath + 'forms/' + formName + '.json');
      }
      return res;
    });
  }

  getFormName(): Promise<string> {
    const form: Observable<Form> = this.getForm();
    return new Promise<string>((resolve, reject) => {
      if (form) {
        form.subscribe(content => {
          resolve(content.label);
        });
      } else {
        reject('Form not found!');
      }
    });
  }

  getCategoryName(index): Promise<string> {
    const form: Observable<Form> = this.getForm();
    return new Promise<string>((resolve, reject) => {
      if (form) {
        form.subscribe(content => {
          resolve(content.children[index].label);
        });
      } else {
        reject('Form not found!');
      }
    });
  }

  getFirstCategoryName(): Promise<string> {
    return this.getCategoryName(0);
  }
}
