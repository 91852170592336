import {BaseEntity} from './base-entity.model';
import {Names} from './names';
import {IGroupOptions} from './interfaces/igroup-options.model';

export class Group extends BaseEntity {
  class = Names.GROUP;
  repeatable: any;
  isCreated: boolean;
  valid = false;
  isTable: boolean;
  numberOfColumn: number;

  constructor(options: IGroupOptions) {
    super(options);
    this.repeatable = options.repeatable || false;
    this.parent = options.parent || '';
    this.isCreated = options.isCreated || false;
    this.isTable = options.isTable || false;
    this.numberOfColumn = options.numberOfColumn || 1;
  }

}
