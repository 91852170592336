export const QueryParameters = {
  ORGANIZATION: 'organization',
  APPOINTMENT_TYPE: 'appointment_type',
  FORM: 'form',
  FORM_VERSION: 'version',
  HIGHLIGHT_QUESTIONS: 'highlight',
  FORM_ID: 'filled_form_id',
  SEND_MAIL: 'send_mail',
};

