import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {
  }

  info(text: any, extra1?: any, extra2?: any) {
    console.log(text, extra1, extra2);
  }

  error(text: any, extra1?: any, extra2?: any) {
    console.error(text, extra1, extra2);
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Also log to console.
      this.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
