export const Options = {
  TYPE: 'type',
  CHILDREN: 'children',
  CHECKED: 'checked',
  VALUE: 'value',
  VALUES: 'values',
  ANSWER_FORMAT: 'answerFormat',
  ANSWER_SUBFORMAT: 'answerSubformat',
  HORIZONTAL: 'horizontal',
};
