export const Names = {
  // Class
  FORM: 'com.biit.webforms.persistence.entity.CompleteFormView',
  CATEGORY: 'com.biit.webforms.persistence.entity.Category',
  QUESTION: 'com.biit.webforms.persistence.entity.Question',
  GROUP: 'com.biit.webforms.persistence.entity.Group',
  SYSTEM_FIELD: 'com.biit.webforms.persistence.entity.SystemField',
  DESCRIPTION_TEXT: 'com.biit.webforms.persistence.entity.Text',
  FILES: 'com.biit.webforms.persistence.entity.AttachedFiles',

  // AnswerType
  TEXT_AREA: 'TEXT_AREA',
  INPUT: 'INPUT',
  RADIO_BUTTON: 'SINGLE_SELECTION_RADIO',
  CHECKBOX: 'MULTIPLE_SELECTION',
  DROPDOWN: 'SINGLE_SELECTION_LIST',
  SLIDER: 'SINGLE_SELECTION_SLIDER',

  // Results
  FORM_RESULT: 'com.biit.form.result.FormResult',
  CATEGORY_RESULT: 'com.biit.form.result.CategoryResult',
  GROUP_RESULT: 'com.biit.form.result.RepeatableGroupResult',
  QUESTION_RESULT: 'com.biit.form.result.QuestionWithValueResult',
  SYSTEM_FIELD_RESULT: 'com.biit.form.result.QuestionWithValueResult',

  // Flow
  TOKEN_COMPARATION_ANSWER: 'com.biit.webforms.persistence.entity.condition.TokenComparationAnswer',
  TOKEN_IN: 'com.biit.webforms.persistence.entity.condition.TokenIn',
  TOKEN_COMPARATION_VALUE: 'com.biit.webforms.persistence.entity.condition.TokenComparationValue',
  TOKEN_BETWEEN: 'com.biit.webforms.persistence.entity.condition.TokenBetween',
  TOKEN: 'com.biit.webforms.persistence.entity.condition.Token',
  TOKEN_EMPTY: 'com.biit.webforms.persistence.entity.condition.TokenEmpty',

  // CSS classes
  CSS_TEXTBOX_QUESTION_VALID: 'fr-answer input',
  CSS_TEXTBOX_QUESTION_INVALID: 'fr-answer input-invalid',
  CSS_TEXTAREA_QUESTION_VALID: 'fr-answer textarea',
  CSS_TEXTAREA_QUESTION_INVALID: 'fr-answer textarea-invalid',
  CSS_RADIO_QUESTION_VALID: 'radio',
  CSS_RADIO_QUESTION_INVALID: 'radio-invalid',
  CSS_DROPDOWN_QUESTION_VALID: 'fr-element fr-answer dropdown',
  CSS_DROPDOWN_QUESTION_INVALID: 'fr-element fr-answer dropdown-invalid',
  CSS_MULTICHECK_QUESTION_VALID: 'checkbox',
  CSS_MULTICHECK_QUESTION_INVALID: 'checkbox-invalid',
  CSS_SLIDER_QUESTION_VALID: 'slider',
  CSS_SLIDER_QUESTION_INVALID: 'slider-invalid'
};
