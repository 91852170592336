import {IBaseQuestionOptions} from './interfaces/ibase-question-options.model';
import {BaseEntity} from './base-entity.model';
import {Category} from './category.model';

export class Files extends BaseEntity {
  files: Set<File> = new Set();
  valid = false;
  required = false;
  path: string[];
  category: Category;

  constructor(options: IBaseQuestionOptions) {
    super(options);
    this.required = options.required || false;
    this.path = options.path || [];
  }

  addFile(file: File): void {
    this.files.add(file);
  }

  isValid(): boolean {
    if (this.required) {
      if (this.files.size > 0) {
        this.valid = true;
        return true;
      } else {
        this.valid = false;
        return false;
      }
    } else {
      this.valid = true;
      return true;
    }
  }

  setCssClass() {
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  deleteFile(file): void {
    this.files.delete(file);
  }

  getFiles(): Set<File> {
    return this.files;
  }

  setFiles(files: Set<File>): void {
    this.files = files;
  }

  setCategory() {
    this.category = this.getCategory();
  }

  getCategoryLabel() {
    return this.category && this.category.label ? this.category.label : null;
  }

  public getCategory(): Category {
    let found = false;
    let parent = this.parent;
    while (!found) {
      if (parent.hasOwnProperty('childrenPaths')) { // childrenPaths has an arrays of references to each question of the category
        found = true;
        return parent as Category;
      }
      parent = parent.parent;
    }
    throw Error('Category not found');
  }

}
