import {QuestionBase} from './question-base.model';
import {IDropdownOptions} from './interfaces/idropdown-options.model';
import {Names} from './names';

export class DropdownQuestion extends QuestionBase<string> {

  controlType = 'dropdown';
  cssTag = 'fr-element fr-answer dropdown';

  constructor(options: IDropdownOptions) {
    super(options);
  }

  setCssClass(): void {
    if (this.isValid()) {
      this.cssTag = Names.CSS_DROPDOWN_QUESTION_VALID;
    } else {
      this.cssTag = Names.CSS_DROPDOWN_QUESTION_INVALID;
    }
  }

  isValid(): boolean {
    if (this.required) {
      return !(this.value === undefined || this.value === '');
    } else {
      return true;
    }
  }

  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueAnswer')) {
      this.value = question.defaultValueAnswer;
    }
  }

  setAnswers(question) {
    const answers = question.children;
    const resultValues = [];
    for (const answer of answers) {
      const dropdownName = answer.name;
      const dropdownLabel = answer.label;
      const dropdownValue = answer.label;
      const dropdownDescription = answer.description;
      resultValues.push({name: dropdownName, label: dropdownLabel, value: dropdownValue, description: dropdownDescription, children: []});
    }
    this.children = resultValues;
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  getAnswerLabel(): any[] {
    for (const child of this.children) {
      if (child.name === this.value) {
        return [child.label];
      }
    }
    return [];
  }
}
