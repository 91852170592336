import {IBaseEntityOptions} from './interfaces/ibase-entity-options.model';
import {IBaseEntity} from './interfaces/ibase-entity.model';
import {Image} from './image.model';

export class BaseEntity implements IBaseEntity {

  class: string;
  name: string;
  label: string;
  comparationId: string;
  creationTime: Date | undefined;
  updateTime: Date | undefined;
  hidden: boolean;
  isHidden: boolean;
  children: BaseEntity[];
  valid: boolean;
  image: Image;
  description: string | undefined;
  parent: BaseEntity = undefined;
  parsedDynamicLabel: any;
  hasDynamicLabel = false;
  path: string[];
  multipleImages: any;

  constructor(options: IBaseEntityOptions) {
    this.class = options.class || '';
    this.name = options.name || '';
    this.label = options.label || '';
    this.comparationId = options.comparationId || '';
    this.creationTime = options.creationTime ? new Date(options.creationTime) : undefined;
    this.updateTime = options.updateTime ? new Date(options.updateTime) : undefined;
    this.hidden = options.hidden || false;
    this.children = options.children || [];
    this.valid = options.valid || false;
    this.image = options.image || undefined;
    this.isHidden = options.isHidden || false;
    this.description = options.description || undefined;
    this.multipleImages = options.multipleImages || undefined;
  }

  getForm(): any {
    if (this.parent === undefined) {
      return this;
    } else {
      return this.parent.getForm();
    }
  }


  getParsedLabel() {
    const oldLabel: string = this.label;
    const tokenDelimiter = ' ';
    return oldLabel.split(tokenDelimiter);
  }

  setParsedDynamicLabelWithReferences() {
    this.setHasDynamicLabel();
    if (this.hasDynamicLabel) {
      const parsedLabel = this.getParsedLabel();
      const parsedReferenceLabel = [];
      for (const stringToken of parsedLabel) {
        const re = /([${])/;
        if (re.test(stringToken)) {
          const referenceName = stringToken.substring(2, stringToken.length - 1);
          const splitReferenceName = this.createPathFromReference(referenceName);
          for (const category of this.getForm().children) {
            for (const entity of category.childrenPaths) {
              if (entity.path && this.comparePaths(splitReferenceName, entity.path)) {
                parsedReferenceLabel.push(entity);
                break;
              }
            }
          }
        } else {
          parsedReferenceLabel.push(stringToken);
        }
      }
      this.parsedDynamicLabel = parsedReferenceLabel;
    }
  }

  createPathFromReference(reference) {
    return (reference + '').split('/');
  }

  comparePaths(referencePath, entityPath) {
    if (!referencePath || !entityPath || referencePath.length !== entityPath.length) {
      return false;
    }
    for (let i = 0; i < entityPath.length; i++) {
      if (entityPath[i] !== referencePath[i]) {
        return false;
      }
    }
    return true;
  }

  setUpdatedLabel() {
    if (this.hasDynamicLabel) {
      const parsedLabel = this.parsedDynamicLabel;
      let updatedLabel = '';
      const lastElement: any = parsedLabel[parsedLabel.length - 1];
      for (const token of parsedLabel) {
        // if (token instanceof QuestionBase || token instanceof SystemField) {
        let value = token.value;
        if (token.label) {
          if (value === undefined) {
            value = '';
          } else {
            value = token.value;
          }
        } else {
          if (value === undefined) {
            value = '';
          }
          value = token;
        }
        if (token.label && token.name === lastElement.name) {
          updatedLabel = updatedLabel.concat(value);
        } else if (token === lastElement) {
          updatedLabel = updatedLabel.concat(value);
        } else {
          updatedLabel = updatedLabel.concat(value, ' ');
        }
      }

      this.label = updatedLabel;
    }
    if (this.parent) {
      this.parent.setUpdatedLabel();
    }
  }

  setHasDynamicLabel(): void {
    const re = /([${])/;
    if (re.test(this.label)) {
      this.hasDynamicLabel = true;
    }
  }


  /**
   * Shows itself and all its children checking first the isHidden property
   */
  show() {
    if (this.isHidden) {
      this.hidden = true;
    } else {
      if (this.areAllChildrenHidden()) {
        this.hidden = true;
      } else {
        this.hidden = false;
        for (const child of this.children) {
          child.show();
        }
      }
    }
  }

  areAllChildrenHidden(): boolean {
    if (this.children.length === 0) {
      return false;
    }
    for (const entity of this.children) {
      if (!entity.isHidden) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns the validity of an entity and updates its validity value.
   * It's override in Questions
   */
  isValid() {
    for (const entity of this.children) {
      if (!entity.hidden && !entity.isHidden &&
          entity.children && !entity.isValid()) {
            this.valid = false;
            return false;
      }
    }
    this.valid = true;
    return true;
  }

  /**
   * Return the visibility of a question.
   * This attribute comes from the specification of Webforms
   */
  getIsHidden() {
    return this.isHidden;
  }

  isShown() {
    return !this.hidden && !this.getIsHidden();
  }

  setAnswers(answers: any): void {
    throw new Error('Method not implemented.');
  }

  setInitialAnswers(question: any): void {
    throw new Error('Method not implemented.');
  }

}
