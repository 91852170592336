// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sendMethod: 'endpoint',
  remote: {
    user: 'webservices@biit-solutions.com',
    password: 'a5f43532ff',
    restUrl: 'https://m3sport.biit-solutions.com/usmo/rest/'
  },
  mailServer: {
    from: '"FormRunner" <formrunner@biit-solutions.com>',
    to: 'info@biit-solutions.com',
    host: '',
    port: '',
    secure: false,
    auth: {
      user: '',
      pass: ''
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
