import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Names} from '../../../../models/names';
import {Group} from '../../../../models/group.model';
import {Table} from '../../../../models/table';
import {QuestionBase} from '../../../../models/question-base.model';
import {LoopGroup} from '../../../../models/loop-group.model';
import {DescriptionText} from '../../../../models/description-text.model';
import {GenerateComparationId, ResolveQuestionType} from '../../question-resolver';
import {TranslateService} from '@ngx-translate/core';
import {BaseEntity} from '../../../../models/base-entity.model';
import {ComponentHelper} from '../../../../helpers/component-helper';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input() table: Table | null = null;
  @Input() readOnly: boolean = false;

  descriptionTextClass: string = Names.DESCRIPTION_TEXT;

  @Output() updateAnswerGroup = new EventEmitter();
  @Output() addLoopGroupComponent = new EventEmitter();
  @Output() deleteLoopGroupComponent = new EventEmitter();

  @Output() addLoopTable = new EventEmitter();
  @Output() deleteLoopTable = new EventEmitter();

  columnValid: boolean[] = [];

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor(private cdRef: ChangeDetectorRef, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.table) {
      if (this.table.children[0].children) {
        // at the beginning the column is invalid
        this.columnValid.push(false);
      }
    }
  }

  createNewGroup(originalGroup: any): LoopGroup {
    const newGroup = new LoopGroup({
      class: Names.GROUP,
      name: originalGroup.name,
      label: originalGroup.label,
      comparationId: GenerateComparationId(),
      creationTime: originalGroup.creationTime,
      updateTime: originalGroup.updateTime,
      parent: originalGroup.parent,
      isCreated: true,
      children: [],
      hidden: originalGroup.hidden
    });
    for (const child of originalGroup.children) {
      newGroup.children.push(this.checkType(child, newGroup) as BaseEntity);
    }
    return newGroup;
  }

  addRow(index: number): void {
    if (!this.table) {
      return;
    }
    const newGroup = this.createNewGroup(this.table.children[index]);
    const children = this.table.children;
    children.splice(index + 1, 0, newGroup);
    this.checkParentValidity();
    this.addLoopTable.emit(newGroup);
  }

  deleteRow(index: number): void {
    if (!this.table) {
      return;
    }
    const children = this.table.children;
    children.splice(index, 1);
    this.checkParentValidity();
    this.deleteLoopTable.emit(this.table);
  }

  addTable(): void {
    if (!this.table) {
      return;
    }
    const newGroup = this.createNewTable(this.table);
    const children = this.table.parent.children;
    const insertPoint = this.getInsertPoint();
    children.splice(insertPoint, 0, newGroup);
    this.checkParentValidity();
    this.addLoopTable.emit(newGroup);
  }

  private getInsertPoint(): number {
    if (!this.table) {
      return -1;
    }
    for (let i = 0; i < this.table.parent.children.length; i++) {
      const entity = this.table.parent.children[i];
      if (entity.comparationId === this.table.comparationId) {
        return i + 1;
      }
    }
    return -1;
  }

  private checkParentValidity(): boolean {
    if (!this.table) {
      return false;
    }
    for (const child of this.table.parent.children) {
      if (child.valid === false) {
        this.table.parent.valid = false;
        return false;
      }
    }
    this.table.parent.valid = true;
    return true;
  }

  deleteTable(): void {
    if (!this.table) {
      return;
    }
    const deletePoint: number = this.getDeletePoint();
    const children = this.table.parent.children;
    children.splice(deletePoint, 1);
    this.checkParentValidity();
    this.deleteLoopTable.emit(this.table);
  }

  private getDeletePoint(): number {
    if (!this.table) {
      return -1;
    }
    for (let i = 0; i < this.table.parent.children.length; i++) {
      const entity = this.table.parent.children[i];
      if (entity.comparationId === this.table.comparationId) {
        return i;
      }
    }
    return -1;
  }

  createNewTable(originalGroup: any): Table {
    const newGroup = new Table({
      class: Names.GROUP,
      name: originalGroup.name,
      label: originalGroup.label,
      comparationId: GenerateComparationId(),
      creationTime: originalGroup.creationTime,
      updateTime: originalGroup.updateTime,
      parent: originalGroup.parent,
      isCreated: true,
      children: [],
      hidden: originalGroup.hidden
    });
    for (const child of originalGroup.children) {
      newGroup.children.push(this.checkType(child, newGroup) as BaseEntity);
    }
    return newGroup;
  }

  createQuestion(question: QuestionBase<any>, questionParent: QuestionBase<any>) {
    const newQuestion = ResolveQuestionType(question, this.translateService, questionParent);
    newQuestion.comparationId = GenerateComparationId();
    newQuestion.parent = questionParent;

    return newQuestion;
  }

  checkType(childElement: any, entityParent: any): any {
    if (childElement instanceof QuestionBase) {
      return this.createQuestion(childElement, entityParent);
    } else if (childElement instanceof DescriptionText) {
      return this.createTextDescription(childElement, entityParent);
    } else if (childElement instanceof Group) {
      const newGroup = new Group({
        class: Names.GROUP,
        name: childElement.name,
        label: childElement.label,
        comparationId: childElement.comparationId,
        creationTime: childElement.creationTime,
        updateTime: childElement.updateTime,
        children: [],
        repeatable: childElement.repeatable,
        isCreated: childElement.isCreated,
        parent: entityParent,
        hidden: childElement.hidden
      });
      newGroup.children = [];
      for (const child of childElement.children) {
        newGroup.children.push(this.checkType(child, newGroup) as BaseEntity);
      }
      return newGroup;
    }
  }

  getDropDownValues(question: QuestionBase<any>) {
    const answers = question.children;
    const resultValues = [];
    for (const answer of answers) {
      const dropdownName = answer.name;
      const dropdownLabel = answer.label;
      const dropdownValue = answer.label;
      const dropdownDescription = answer.description;
      const answerElement = {
        name: dropdownName,
        label: dropdownLabel,
        value: dropdownValue,
        description: dropdownDescription,
        children: []
      };
      resultValues.push(answerElement as never);
    }
    return resultValues;
  }

  createTextDescription(textDescription: DescriptionText, newParent: any): DescriptionText {
    return new DescriptionText({
      class: Names.DESCRIPTION_TEXT,
      comparationId: textDescription.comparationId,
      creationTime: textDescription.creationTime,
      updateTime: textDescription.updateTime,
      createdBy: textDescription.createdBy,
      parent: newParent,
      updatedBy: textDescription.updatedBy,
      name: textDescription.name,
      label: textDescription.label,
      hidden: textDescription.hidden,
      fieldName: textDescription.fieldName,
      description: textDescription.description,
      path: textDescription.path,
      valid: true
    });
  }

  updateAnswerToParent(event: Event) {
    this.checkTableValid();
    this.updateAnswerGroup.emit(event);
  }

  checkTableValid(): void {
    if (!this.table) {
      return;
    }
    for (let i = 0; i < this.table.children[0].children.length; i++) {
      this.checkColumnValid(i);
    }
  }

  checkColumnValid(column: number) {
    if (!this.table) {
      return;
    }
    for (const child of this.table.children) {
      if (!child.children[column].valid) {
        this.columnValid[column] = false;
        this.cdRef.detectChanges();
        return;
      }
    }
    this.columnValid[column] = true;
    this.cdRef.detectChanges();
  }

  addLoopGroups(event: Event) {
    this.addLoopGroupComponent.emit(event);
  }

  sendEvent(event: Event) {
    this.addLoopGroupComponent.emit(event);
  }

  deleteLoopGroups(event: Event) {
    this.deleteLoopGroupComponent.emit(event);
  }

  sendDeleteEvent(event: Event) {
    this.deleteLoopGroupComponent.emit(event);
  }
}
