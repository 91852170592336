import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Category} from '../../../../models/category.model';
import {Names} from '../../../../models/names';
import {ComponentHelper} from '../../../../helpers/component-helper';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnChanges {


  @Input() category: Category | null = null;
  @Input() readOnly: boolean = false;

  descriptionTextClass: string = Names.DESCRIPTION_TEXT;
  imageUrl: string = '';
  data: any = null;
  width: number = 0;
  height: number = 0;
  categoryName: string = '';

  @Output() createLoopGroup = new EventEmitter();
  @Output() deleteLoopGroup = new EventEmitter();

  @Output() isQuestionValid = new EventEmitter();
  @Output() updateAnswerEvent = new EventEmitter();

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor() {
  }

  ngOnChanges() {
    if (this.category) {
      this.categoryName = this.category.label;
    }
    if(!this.category) {
      throw new Error('Category not found');
    }
    if (this.category.image) {
      if (this.category.image.imageUrl) {
        this.imageUrl = this.category.image.imageUrl;
        this.width = this.category.image.width;
        this.height = this.category.image.height;
      }
      if (this.category.image.data) {
        this.data = this.category.image.data;
        this.width = this.category.image.width;
        this.height = this.category.image.height;
      }
    }
  }

  updateAnswer(event: any): void {
    if(!this.category) {
      throw new Error('Category not found');
    }
    if (event[0].valid === true) {
      for (const children of this.category.children) {
        if (children.hidden === false) {
          if (children.valid === false) {
            this.category.valid = false;
            this.updateAnswerEvent.emit(event);
            return;
          }
        }
      }
      this.category.valid = true;
    } else {
      this.category.valid = false;
    }
    this.updateAnswerEvent.emit(event);
  }


  addGroup(event: any): void {
    this.createLoopGroup.emit(event);
  }

  deleteGroup(event: any): void {
    this.deleteLoopGroup.emit(event);
  }
}
