import {QuestionBase} from '../../models/question-base.model';
import {TextAreaQuestion} from '../../models/text-area-question.model';
import {DropdownQuestion} from '../../models/dropdown-question.model';
import {RadioButtonQuestion} from '../../models/radio-button-question.model';
import {MultiCheckBoxQuestion} from '../../models/multi-check-box-question.model';
import {TextboxQuestion} from '../../models/textbox-question.model';
import {TranslateService} from '@ngx-translate/core';
import { v4 as uuid } from 'uuid'

export function ResolveQuestionType(question, translate: TranslateService, questionParent?): QuestionBase<any> {
  const answerType = question.controlType;

  const questionComparationId = question.comparationId;
  if (answerType === 'textarea') {
    if (question.hasOwnProperty('defaultValueString')) {
      return new TextAreaQuestion({
        name: question.name,
        path: question.path,
        label: question.label,
        value: question.defaultValueString,
        required: question.required,
        hidden: question.hidden,
        comparationId: questionComparationId,
        creationTime: question.creationTime,
        updateTime: question.updateTime,
        parent: questionParent
      });
    } else {
      return new TextAreaQuestion({
        name: question.name,
        label: question.label,
        value: '',
        path: question.path,
        required: question.required,
        hidden: question.hidden,
        comparationId: questionComparationId,
        creationTime: question.creationTime,
        updateTime: question.updateTime,
        parent: questionParent
      });
    }
  } else if (answerType === 'dropdown') {
    return new DropdownQuestion({
      name: question.name,
      path: question.path,
      label: question.label,
      required: question.required,
      hidden: question.hidden,
      comparationId: questionComparationId,
      creationTime: question.creationTime,
      updateTime: question.updateTime,
      children: GetDropDownValues(question),
      parent: questionParent
    });
  } else if (answerType === 'radiobutton') {
    return new RadioButtonQuestion({
      name: question.name,
      path: question.path,
      label: question.label,
      required: question.required,
      hidden: question.hidden,
      horizontal: question.horizontal,
      comparationId: questionComparationId,
      creationTime: question.creationTime,
      updateTime: question.updateTime,
      children: GetDropDownValues(question),
      parent: questionParent
    });
  } else if (answerType === 'multicheck') {
    return new MultiCheckBoxQuestion({
      name: question.name,
      path: question.path,
      label: question.label,
      required: question.required,
      hidden: question.hidden,
      value: GetMultipleSelectionValues(question),
      comparationId: questionComparationId,
      creationTime: question.creationTime,
      updateTime: question.updateTime,
      parent: questionParent
    });
  } else if (answerType === 'textbox') {
    if (question.hasOwnProperty('answerFormat')) {
      return new TextboxQuestion({
        name: question.name,
        path: question.path,
        label: question.label,
        required: question.required,
        answerFormat: question.answerFormat,
        answerSubformat: question.answerSubformat,
        hidden: question.hidden,
        comparationId: questionComparationId,
        creationTime: question.creationTime,
        updateTime: question.updateTime,
        parent: questionParent
      }, translate);
    } else {
      return new TextboxQuestion({
        name: question.name,
        path: question.path,
        label: question.label,
        required: question.required,
        hidden: question.hidden,
        comparationId: questionComparationId,
        creationTime: question.creationTime,
        updateTime: question.updateTime,
        parent: questionParent
      }, translate);
    }
  }
// If type is not recognised
  else {
    return new DropdownQuestion({
      name: question.name,
      path: question.path,
      label: question.label,
      hidden: question.hidden,
      comparationId: questionComparationId,
      creationTime: question.creationTime,
      updateTime: question.updateTime,
      parent: questionParent
    });
  }
}


export function GetDropDownValues(question): any[] {
  const resultValues: any[] = [];
  if (question && question.children) {
    for (const answer of question.children) {
      const dropdownName = answer.name;
      const dropdownLabel = answer.label;
      const dropdownValue = answer.label;
      const dropdownDescription = answer.description;
      const answerElement = {
        name: dropdownName,
        label: dropdownLabel,
        value: dropdownValue,
        description: dropdownDescription,
        parent: question,
        children: []
      };
      resultValues.push(answerElement);
    }
  }
  return resultValues;
}

export function UpdateAnswerToParent(event) {
  if (event[0].valid === true) {
    for (const child of this.group.children) {
      if (child.hidden === false) {
        if (child.valid === false) {
          this.group.valid = false;
          this.updateAnswerGroup.emit(event);
          return;
        }
      }
    }
    this.group.valid = true;
  } else {
    this.group.valid = false;
  }
  this.updateAnswerGroup.emit(event);
}

export function GenerateComparationId(): string {
  return uuid();
}

export function GetRadioButtonValues(question: any): any[] {
  const answers = question.children;
  const resultValues: any[] = [];
  for (const answer of answers) {
    const radioName = answer.name;
    const radioLabel = answer.label;
    const radioDescription = answer.description;
    const answerElement = {name: radioName, label: radioLabel, value: undefined, description: radioDescription, children: [], parent: question};
    if (answer.children.length > 0) {
      answerElement.children = this.getRadioSubanswers(answer.children);
    }
    resultValues.push(answerElement);
  }
  return resultValues;
}

export function GetRadioSubanswers(question): any[] {
  const subanswers: any[] = [];
  for (const child of question.children) {
    const subanswerName = child.name;
    const subanswerLabel = child.label;
    const subanswerDescription = child.description;
    const subanswer = {name: subanswerName, label: subanswerLabel, value: undefined, description: subanswerDescription, children: [], parent: question};
    if (child.children.length > 0) {
      subanswer.children = this.getRadioSubanswers(child.children) as never[];
    }
    subanswers.push(subanswer);
  }
  return subanswers;
}

export function GetMultipleSelectionValues(question: any): any[] {
  const answers = question.children;
  const resultValues: any[] = [];
  for (const answer of answers) {
    const answerName = answer.name;
    const answerLabel = answer.label;
    const answerValue = answer.label;
    const answerDescription = answer.description;
    const answerElement = {
      name: answerName,
      label: answerLabel,
      value: answerValue,
      checked: false,
      description: answerDescription,
      children: []
    };
    if (answer.children.length > 0) {
      answerElement.children = this.getCheckboxSubanswers(answer.children) as never[];
    }
    resultValues.push(answerElement);
  }
  return resultValues;
}

