import { Component, OnInit, Input } from '@angular/core';
import {DescriptionText} from '../../../../models/description-text.model';

@Component({
  selector: 'app-description-text',
  templateUrl: './description-text.component.html',
  styleUrls: [ './description-text.component.css']
})
export class DescriptionTextComponent implements OnInit {

  @Input() descriptionText: DescriptionText | null = null;

  constructor() { }

  ngOnInit() {}

}
