export const Header = {
  CLASS: 'class',
  COMPARATION: 'comparationId',
  CREATION_TIME: 'creationTime',
  UPDATE_TIME: 'updateTime',
  NAME: 'name',
  LABEL: 'label',
  CHILDREN: 'children',
  VALUES: 'values',
  ANSWER_LABELS: 'answerLabels',
  REPEATABLE: 'repeatable',
  FIELD_NAME: '',
};
