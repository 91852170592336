import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RadioButtonAnswer} from '../../../../models/radio-button-answer';
import {QuestionBase} from '../../../../models/question-base.model';

@Component({
  selector: 'app-radio-subanswers',
  templateUrl: './radio-subanswers.component.html',
  styleUrls: ['./radio-subanswers.component.css']
})
export class RadioSubanswersComponent implements OnInit {

  @Input() subanswers: RadioButtonAnswer[] = [];
  @Input() question: QuestionBase<any> | null = null;
  @Input() parentAnswer: RadioButtonAnswer | null = null;
  @Input() readOnly: boolean = false;

  @Output() updateSubanswerRadioToParent = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.subanswers.forEach(value => {
      value.checked = false;
    });
  }

  updateAnswers(subanswer: RadioButtonAnswer) {
    subanswer.checked = true;
    this.updateSubanswerRadioToParent.emit(subanswer);
  }

}
