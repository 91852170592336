import {BaseEntity} from './base-entity.model';
import {Names} from './names';
import {ISystemFieldOptions} from './interfaces/isystem-field-options.model';

export class SystemField extends BaseEntity {
  class = Names.SYSTEM_FIELD;
  createdBy: any;
  updatedBy: any;
  fieldName: string;
  parent;
  value;
  valid: boolean;
  path: string[];
  hidden = false;

  constructor(options: ISystemFieldOptions) {
    super(options);
    this.createdBy = options.createdBy || 0;
    this.updatedBy = options.updatedBy || 0;
    this.fieldName = options.fieldName || '';
    this.parent = options.parent || undefined;
    this.value = options.value || '';
    this.valid = true;
    this.path = options.path || [];
  }

  show(): void {
    this.hidden = false;
  }

  isValid(): boolean {
    return true;
  }
}
