import {Injectable} from '@angular/core';
import {QueryParameters} from '../models/query-parameters';
import {Form} from '../models/form.model';
import {Options} from '../models/options';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LogService} from './log.service';
import {ActivatedRoute} from '@angular/router';
import {MailService} from './mail.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubmitResponseService {

  private sendMethod: string = environment.sendMethod;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private mailService: MailService,
    private logger: LogService) {
  }


  submit(answers): Promise<any> {
    this.logger.info('Answered form: ', answers);
    return new Promise<any>(resolve => {
      this.route.queryParams.subscribe(queryParams => {
        if (this.sendMethod === 'email' || queryParams[QueryParameters.SEND_MAIL]) {
          // Sending email
          this.mailService.sendEmail(this.emailBody(answers));
        } else {
          // Calling endpoint
          const service = '/submit';
          const body = this.requestFromFormAnswers(answers);
          body[QueryParameters.ORGANIZATION] = queryParams[QueryParameters.ORGANIZATION] || 'Orbis Sport';
          body[QueryParameters.APPOINTMENT_TYPE] = queryParams[QueryParameters.APPOINTMENT_TYPE] || 'Energy';
          const customHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          this.logger.info('Body:', body);
          resolve(this.http.post(service, body, {headers: customHeaders}));
        }
      });
    });
  }

  private recursiveCopy(node) {
    const newNode = {name: node.label || node.name, children: [], class: node.class};
    if (node.values) {
      newNode[Options.VALUES] = node.values;
    }
    for (const childNode of node.children) {
      newNode.children.push(this.recursiveCopy(childNode));
    }
    return newNode;
  }

  private retrieveAnswers(node) {
    let answersList = [];
    if (node.values) {
      answersList.push(node);
    } else if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        answersList = answersList.concat(this.retrieveAnswers(childNode));
      }
    }
    return answersList;
  }

  private requestFromFormAnswers(form: Form): any {
    const questionAnswers = this.retrieveAnswers(form);
    this.logger.info('QuestionAnswers:', questionAnswers);

    return {
      // TODO(jhortelano): WTF???
      filled_form_id: Math.floor(Math.random() * 1000),
      user_comparation_id: questionAnswers && questionAnswers.find(q => q.name === 'user_comparation_id') ?
        questionAnswers.find(q => q.name === 'user_comparation_id').values[0] : null,
      initials: questionAnswers && questionAnswers.find(q => q.name === 'profile_initials') ?
        questionAnswers.find(q => q.name === 'profile_initials').values[0] : null,
      first_name: questionAnswers && questionAnswers.find(q => q.name === 'profile_first_name') ?
        questionAnswers.find(q => q.name === 'profile_first_name').values[0] : null,
      last_name: questionAnswers && questionAnswers.find(q => q.name === 'profile_last_name') ?
        questionAnswers.find(q => q.name === 'profile_last_name').values[0] : null,
      birthday: questionAnswers && questionAnswers.find(q => q.name === 'profile_birthday') ?
        questionAnswers.find(q => q.name === 'profile_birthday').values[0] : null,
      phone: questionAnswers && questionAnswers.find(q => q.name === 'Phone') ?
        questionAnswers.find(q => q.name === 'Phone').values[0] : null,
      email: questionAnswers && questionAnswers.find(q => q.name === 'Email') ?
        questionAnswers.find(q => q.name === 'Email').values[0] : null,
      street: questionAnswers && questionAnswers.find(q => q.name === 'person_address_street') ?
        questionAnswers.find(q => q.name === 'person_address_street').values[0] : null,
      city: questionAnswers && questionAnswers.find(q => q.name === 'person_address_city') ?
        questionAnswers.find(q => q.name === 'person_address_city').values[0] : null,
      postalcode: questionAnswers && questionAnswers.find(q => q.name === 'person_address_postal') ?
        questionAnswers.find(q => q.name === 'person_address_postal').values[0] : null,

      form_name:  form.label,
      form_version: form.version,
      screening_examinations: '',
      appointment_id: '',
    };
  }

  private emailBody(answers): string {
    return '';
  }
}
