import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Category} from '../../../../models/category.model';
import {Form} from '../../../../models/form.model';
import {ComponentHelper} from '../../../../helpers/component-helper';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnChanges {

  category: Category | null = null;
  @Input() formRunner: Form | null  = null;
  @Input() categoryIndex: number = 0;
  @Input() readOnly: boolean = false;
  imageUrl: string = '';
  width: number = 0;
  height: number = 0;
  data: any;

  @Output() createLoopGroup = new EventEmitter();
  @Output() deleteLoopGroup = new EventEmitter();
  @Output() updateAnswerEvent = new EventEmitter();

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor() {
  }

  ngOnChanges() {
    if (!this.formRunner) {
      return;
    }
    if (!this.categoryIndex) {
      this.categoryIndex = 0;
    }
    this.category = this.formRunner.children[this.categoryIndex];
    if (this.formRunner.image !== undefined) {
      if (this.formRunner.image.imageUrl !== undefined) {
        this.imageUrl = this.formRunner.image.imageUrl;
        this.width = this.formRunner.image.width;
        this.height = this.formRunner.image.height;
      }
      if (this.formRunner.image.data !== undefined) {
        this.data = this.formRunner.image.data;
        this.width = this.formRunner.image.width;
        this.height = this.formRunner.image.height;
      }
    }

  }

  updateAnswer(event: any): void {
    if (!this.formRunner) {
      return;
    }
    if (event[0].valid === true) {
      for (const children of this.formRunner.children) {
        if (children.hidden === false) {
          if (children.valid === false) {
            this.formRunner.valid = false;
            this.updateAnswerEvent.emit(event);
            return;
          }
        }
      }
      this.formRunner.valid = true;
    } else {
      this.formRunner.valid = false;
    }
    this.updateAnswerEvent.emit(event);
  }

  addGroup(event: any): void {
    this.createLoopGroup.emit(event);
  }

  deleteGroup(event: any): void {
    this.deleteLoopGroup.emit(event);
  }

}
