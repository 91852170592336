import {Component, Input, ViewChild, OnInit, Output, EventEmitter, ElementRef} from '@angular/core';
import {Files} from '../../../../models/files.model';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  @Input() readOnly: boolean = false;
  @Input() fileSelector: Files | null = null;
  @ViewChild('file') file: ElementRef | null = null;
  uploading = false;
  uploadSuccessful = false;
  public files: Set<File> = new Set();

  @Output() updateAnswer: EventEmitter<[Files, boolean]> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  addFiles() {
    if (!this.file) {
      throw Error('File not found');
    }
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    if (!this.file) {
      throw Error('File not found');
    }
    if (!this.fileSelector){
      throw Error('FileSelector not found');
    }
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(files[key]);
        this.getBase64(files[key]).then(
        );
      }
    }
    this.fileSelector.files = this.files;
    this.fileSelector.isValid();
    this.updateAnswer.emit([this.fileSelector, false]);
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  deleteFile(file: any) {
    if (!this.fileSelector){
      throw Error('FileSelector not found');
    }
    this.files.delete(file);
    this.fileSelector.files = this.files;
    this.fileSelector.isValid();
    this.updateAnswer.emit([this.fileSelector, false]);
  }

  fileTooBig(file: File): boolean {
    if (this.fileSelector !== undefined) {
      return file.size > 100000000;
    }
    return false;
  }

  mandatoryInvalid(): boolean {
    if (this.fileSelector) {
      return !this.fileSelector.isValid() && this.fileSelector.required;
    }
    return true;
  }

  // In the html shows the gry asterisk
  mandatoryValid(): boolean {
    if (this.fileSelector) {
      return this.fileSelector.isValid() && this.fileSelector.required;
    }
    return true;
  }

}
