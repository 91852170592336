/***
 *            ██  █ ███ ███  █  ████  ████
 *            █ █ █ █ █  █   █  █     █
 *            █  ██ █ █  █   █  █     ███
 *            █   █ █ █  █   █  █     █
 *            █   █ ███  █   █  ████  ████
 *
 *          THIS COMPONENT IS NOT IMPORTED!!!!
 *    Any change here must be changed on server side as well.
 */

import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormRunnerComponent} from './ui/components/shared/form-runner/form-runner.component';
import {Observable} from 'rxjs';
import {QuestionService} from './services/question.service';
import {Group} from './models/group.model';
import {Category} from './models/category.model';
import {SubmitResponseService} from './services/submit-response.service';
import {FormService} from './services/form.service';
import {QuestionBase} from './models/question-base.model';
import {ConditionService} from './services/condition.service';
import {BaseEntity} from './models/base-entity.model';
import {SubmitService} from './services/submit.service';
import {LogService} from './services/log.service';
import {QueryParameters} from './models/query-parameters';
import {FormSettings} from './models/form-settings';
import {CategoryFile} from './models/category-file.model';
import {Flow} from './models/flow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('formrunner') formRunnerComponent: FormRunnerComponent;
  flowNavigation: Flow[];

  categoryList: Category[];

  formRunner: any;

  initialAnswers: Observable<any>;
  highlightedAnswers: Observable<any>;

  questions: any[];
  formName: any;
  categoryName: any;
  currentCategoryIndex: any;

  buttonPreviousDisabled: boolean;
  buttonNextDisabled: boolean;
  buttonSubmitStatus: boolean;
  buttonPreviousHidden = false;
  buttonNextHidden = false;

  logs: boolean;

  submitSuccess: boolean;

  jsonForm: Observable<any>;

  formSettings: FormSettings;

  constructor(
    private cdRef: ChangeDetectorRef,
    private questionService: QuestionService,
    private formService: FormService,
    private submitService: SubmitService,
    private conditionService: ConditionService,
    private submitResponseService: SubmitResponseService,
    private logger: LogService
  ) {
    // this.configFile = config;
    const params = this.getParams();
    if (params[QueryParameters.FORM]) {
      this.jsonForm = formService.getForm(params[QueryParameters.FORM]);
    } else {
      this.jsonForm = formService.getForm();
    }
    if (params[QueryParameters.HIGHLIGHT_QUESTIONS] && params[QueryParameters.HIGHLIGHT_QUESTIONS] === 'true') {
      this.highlightedAnswers = new Observable<any>();
    }
    this.jsonForm.subscribe(
      form => {
        this.formRunner = questionService.createForm(form);
        this.flowNavigation = questionService.getFlows(form);
        if (this.flowNavigation[0] !== undefined) {
          this.showStartForm();
        } else {
          this.showAll();
        }
        // this.showAll();
        this.categoryList = this.formRunner.children;
        this.hidePreviousAndNextButtonsForOneCategory();
      }
    );
    this.initialAnswers = this.getAnswer();
    this.submitSuccess = false;
    this.buttonNextDisabled = true;
    this.buttonPreviousDisabled = true;
    this.buttonSubmitStatus = true;
  }


  ngOnInit(): void {
    this.formService.getFormName()
      .then(data => {
        // run sometimes later
        this.formName = data;
      });

    this.formService.getFirstCategoryName()
      .then(data => {
        // run sometimes later
        this.categoryName = data;
      });
    // run first

    fetch('assets/form-settings/form-settings.json').then(res => res.json())
      .then(data => {
        const params = this.getParams();
        const formName = params[QueryParameters.FORM];
        const formVersion = params[QueryParameters.FORM_VERSION];
        this.formSettings = (data as FormSettings[]).find(d => d.formName === formName &&
          (!formVersion || d.formVersion === formVersion));
      });
  }

  ngAfterViewInit(): void {
    // Prevent navigation back
    window.onbeforeunload = () => {
      return '';
    };
    // Remove navigation prompt
    // window.onbeforeunload = null;
  }

  public getAnswer(): Observable<any> {
    const params = this.getParams();
    const uuid = params[QueryParameters.FORM_ID];
    if (uuid) {
      return this.formService.getForm(uuid);
    } else {
      return new Observable<any>();
    }
  }

  // Show the previous category, if not, disable
  onPrevious() {
    this.formRunner = this.formRunnerComponent.getJsonForm()[0];
    this.currentCategoryIndex = this.formRunnerComponent.getJsonForm()[1];
    this.formRunnerComponent.previousPage();
    const index = this.checkPreviousCategoryIndex();
    this.setCategoryName(index);
    this.enableNext();
  }

  // Show the next category, if not, disable
  onNext() {
    this.formRunner = this.formRunnerComponent.getJsonForm()[0];
    this.currentCategoryIndex = this.formRunnerComponent.getJsonForm()[1];
    this.formRunnerComponent.nextPage();
    const index = this.checkNextCategoryIndex();
    this.setCategoryName(index);
    this.enablePrevious();
  }

  disablePrevious() {
    this.buttonPreviousDisabled = true;
  }

  disableNext() {
    this.buttonNextDisabled = true;
  }

  enablePrevious() {
    this.buttonPreviousDisabled = false;
  }

  enableNext() {
    this.buttonNextDisabled = false;
  }

  disableSubmit() {
    this.buttonSubmitStatus = true;
  }

  enableSubmit() {
    this.buttonSubmitStatus = false;
  }

  public onFiles(files: Set<CategoryFile>): void {
    if (files.size > 0) {
      files.forEach(file => {
        this.logger.info(file.file, file.category);
      });
    }
  }

  onSubmit(answers): void {
    // Call submit-form-service
    // Show waiting animation
    this.showProgress();
    this.submitResponseService.submit(answers)
      .then(
        response => {
          this.logger.info('Response:', response);
          // Show success View (Link to download the App?)
          this.stopProgress();
          this.showSuccess();
        },
        error => {
          this.logger.info('Error:', error);
          // Show error message (Retry?)
          this.stopProgress();
          this.showSubmitError();
        });
  }

  updateCategoryList(categoryList) {
    this.categoryList = categoryList;
    this.cdRef.detectChanges();
  }

  updateSubmitStatus(status) {
    const previousValue = this.buttonSubmitStatus;
    this.buttonSubmitStatus = status;
    if (previousValue !== status) {
      this.cdRef.detectChanges();
    }
  }

  updateNextStatus(status) {
    const previousValue = this.buttonNextDisabled;
    this.buttonNextDisabled = status;
    if (status && this.categoryList[this.currentCategoryIndex]) {
      this.categoryList[this.currentCategoryIndex].markAsInvalid();
    } else if (this.categoryList[this.currentCategoryIndex]) {
      this.categoryList[this.currentCategoryIndex].markAsValid();
    }
    if (previousValue !== status) {
      this.cdRef.detectChanges();
    }
  }

  private updatePreviousStatus(status) {
    this.buttonPreviousDisabled = status;
  }

  public navigateToCategory(cat: string) {
    const index = this.findCategory(cat);
    this.setCategoryName(index);
    this.currentCategoryIndex = index;
    this.questions = this.formRunner.children[index].children;
    if (this.currentCategoryIndex > 0) {
      this.enablePrevious();
    } else {
      this.disablePrevious();
    }
    if (this.logs) {
      this.logger.info('Navigation panel: ', index + ' ' + this.formRunner.children[index].name +
        '  [' + new Date().toLocaleString() + ']');
    }
    const last = this.formRunner.children.length - 1;
    const nextCat = this.getNextNotHiddenCategory();
    if (last === nextCat) {
      this.buttonNextDisabled = true;
    }
    this.formRunnerComponent.navigateToCategory(index);
  }

  private findCategory(category: string) {
    for (let i = 0; i < this.formRunner.children.length; i++) {
      if (this.formRunner.children[i].name === category) {
        return i;
      }
    }
  }

  // Set the name of the current shown category
  private setCategoryName(index) {
    this.categoryName = this.formRunner.children[index].label;
  }

  // Check if it's the last element of the array
  private checkNextCategoryIndex() {
    let index = this.currentCategoryIndex;
    const categories = this.formRunner.children;
    if (index + 2 === categories.length) {
      this.disableNext();
    }
    index = this.getNextNotHiddenCategory();
    this.currentCategoryIndex = index;
    return index;

  }

  // Check if it's the first element of the array
  private checkPreviousCategoryIndex() {
    let index = this.currentCategoryIndex;
    if (index - 1 === 0 || !this.areAllCategoriesDisabled()) {
      this.disablePrevious();
    }
    index = this.getFirstNotHiddenCategory();
    this.currentCategoryIndex = index;
    return index;
  }

  private areAllCategoriesDisabled() {
    for (let i = 0; i < this.currentCategoryIndex.length; i++) {
      if (this.formRunner.children[i].hidden === true) {
        return false;
      }
    }
    return true;
  }

  private getFirstNotHiddenCategory() {
    for (let i = this.currentCategoryIndex - 1; i >= 0; i--) {
      if (this.formRunner.children[i].hidden === false) {
        return i;
      }
    }
    return this.currentCategoryIndex;
  }

  private getNextNotHiddenCategory() {
    for (let i = this.currentCategoryIndex + 1; i <= this.formRunner.children.length - 1; i++) {
      if (this.formRunner.children[i].hidden === false) {
        return i;
      }
    }
    return this.currentCategoryIndex;
  }

  public onSubmitPressed() {
    if (this.logs) {
      this.logger.info('Submit button pressed.: ', this.formRunner + '  [' + new Date().toLocaleString() + ']');
    }
    this.formRunnerComponent.submitForm();
    this.formRunnerComponent.hideUI();
    this.showSubmitSuccess();

  }

  private showSubmitSuccess() {
    this.submitSuccess = true;
  }

  public goBack() {
    this.formRunnerComponent.showUI();
    this.submitSuccess = false;
  }

  private showAll() {
    for (const child of this.formRunner.children) {
      child.hidden = false;
      this.showCategory(child);
    }
  }

  private showCategory(category: Category) {
    category.hidden = false;
    for (const entity of category.children) {
      if (entity instanceof Group) {
        this.showGroup(entity);
      } else {
        this.showQuestion(entity as QuestionBase<string>);
      }
    }
  }

  private showGroup(group: Group) {
    group.hidden = false;
    for (const entity of group.children) {
      if (entity instanceof Group) {
        this.showGroup(entity);
      } else {
        this.showQuestion(entity as unknown as QuestionBase<string>);
      }
    }
  }

  private showQuestion(question: QuestionBase<string>) {
    question.hidden = false;
  }

  private checkFlowFromQuestion(question: QuestionBase<string>): boolean {
    for (const navigation of this.flowNavigation) {
      if (navigation.originId[navigation.originId.length - 1] === question.name) {
        return true;
      }
    }
    return false;
  }

  private checkFlowFromGroup(group: Group): boolean {
    if (!group || !group.children || !group.children.length) {
      return true;
    }
    const entity: BaseEntity = group.children[0];
    if (entity instanceof Group) {
      return this.checkFlowFromGroup(entity);
    } else {
      return this.checkFlowFromQuestion(entity as unknown as QuestionBase<string>);
    }
  }

  private hidePreviousAndNextButtonsForOneCategory() {
    if (this.categoryList.length <= 1) {
      this.buttonNextHidden = true;
      this.buttonPreviousHidden = true;
    }
  }

  private showStartForm() {
    for (const child of this.formRunner.children) {
      child.hidden = false;
      for (const grandchild of child.children) {
        if (grandchild.repeatable !== undefined) {
          if (this.checkFlowFromGroup(grandchild)) {
            this.showGroup(grandchild);
            return;
          } else {
            this.showGroup(grandchild);
          }
        } else {
          if (this.checkFlowFromQuestion(grandchild)) {
            this.showQuestion(grandchild);
            return;
          } else {
            this.showQuestion(grandchild);
          }
        }
      }
    }
  }

  private getParams() {
    const params = window.location.search.substring(1).split('&');
    const paramsJson = {};
    for (const param of params) {
      const p = param.split('=');
      paramsJson[p[0]] = p[1];
    }
    return paramsJson;
  }

  private showProgress() {
    this.logger.info('showProgress');
  }

  private stopProgress() {
    this.logger.info('stopProgress');
  }

  private showSuccess() {
    this.logger.info('showSuccess');
  }

  private showSubmitError() {
    this.logger.info('showSubmitError');
  }
}
