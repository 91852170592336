import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormService} from './services/form.service';
import {SubmitResponseService} from './services/submit-response.service';
import {ConditionService} from './services/condition.service';
import {QuestionService} from './services/question.service';
import {SubmitService} from './services/submit.service';
import {LogService} from './services/log.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormRunnerModule} from './ui/components/shared/form-runner/form-runner.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FormRunnerModule,
    RouterModule.forRoot([])
  ],
  providers: [
    FormService,
    QuestionService,
    SubmitService,
    ConditionService,
    SubmitResponseService,
    LogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
