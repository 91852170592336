import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Names} from '../../../../models/names';
import {Group} from '../../../../models/group.model';
import {QuestionBase} from '../../../../models/question-base.model';
import {LoopGroup} from '../../../../models/loop-group.model';
import {DescriptionText} from '../../../../models/description-text.model';
import {GenerateComparationId, ResolveQuestionType} from '../../question-resolver';
import {TranslateService} from '@ngx-translate/core';
import {BaseEntity} from '../../../../models/base-entity.model';
import {Files} from '../../../../models/files.model';
import {ComponentHelper} from '../../../../helpers/component-helper';

@Component({
  selector: 'app-loop-group',
  templateUrl: './loop-group.component.html',
  styleUrls: ['./loop-group.component.css']
})
export class LoopGroupComponent {

  @Input() group: Group | null = null;
  @Input() readOnly: boolean = false;

  descriptionTextClass: string = Names.DESCRIPTION_TEXT;

  @Output() updateAnswerGroup = new EventEmitter();

  @Output() addLoopGroupLGComponent: EventEmitter<Group> = new EventEmitter();
  @Output() deleteLoopGroup: EventEmitter<Group> = new EventEmitter();

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor(private translateService: TranslateService) {
  }

  // TODO(jhortelano): duplicated code
  updateAnswerToParent(event : any): void {
    if (!this.group) {
      return;
    }
    if (event[0].valid === true) {
      this.group.children.filter(child => !!child).forEach(child => {
        if (child.hidden === false && child.valid == false) {
          if (this.group) {
            this.group.valid = false;
          }
          this.updateAnswerGroup.emit(event);
          return;
        }
      });
      this.group.valid = true;
    } else {
      this.group.valid = false;
    }
    this.updateAnswerGroup.emit(event);
  }

  checkParentValidity(): boolean {
    if (!this.group) {
      return false;
    }
    for (const child of this.group.parent.children) {
      if (child.valid === false) {
        this.group.parent.valid = false;
        return false;
      }
    }
    this.group.parent.valid = true;
    return true;
  }

  addLoopGroup(sourceGroup: Group | null): void {
    if (!this.group) {
      return;
    }
    const newGroup = this.createNewGroup(sourceGroup as Group);
    const insertPoint: number = this.getInsertPoint(this.group);
    this.group.parent.children.splice(insertPoint, 0, newGroup);
    this.checkParentValidity();
    this.addLoopGroupLGComponent.emit(newGroup);
  }

  getInsertPoint(sourceGroup: Group | null): number {
    if (sourceGroup == null) {
      return -1;
    }
    if (this.group && this.group.parent && this.group.parent.children) {
      const index: number = this.group.parent.children.indexOf(sourceGroup);
      if (index) {
        return index + 1;
      }
    }
    return 1;
  }

  isLastLoopGroup(sourceGroup: Group): boolean {
    if (!this.group) {
      return false;
    }
    const index: number = this.group.parent.children.indexOf(sourceGroup);
    console.log(sourceGroup.name, index, index < this.group.parent.children.length - 1);
    if (index !== undefined && index < this.group.parent.children.length - 1) {
      // Has a sibling with the same technical name.
      return this.group.parent.children[index + 1].name !== sourceGroup.name;
    }
    return true;
  }

  deleteGroup(sourceGroup: Group | null): void {
    if (!this.group) {
      return;
    }
    const deletePoint = this.group.parent.children.indexOf(sourceGroup as Group);
    const children = this.group.parent.children;
    children.splice(deletePoint, 1);
    this.checkParentValidity();
    this.deleteLoopGroup.emit(this.group);
  }

  getCategory(): any {
    if (!this.group) {
      return null;
    }
    let found = false;
    let parent = this.group.parent;
    while (!found) {
      if (parent.hasOwnProperty('childrenPaths')) {
        found = true;
        return parent;
      }
      parent = parent.parent;
    }
    return null;
  }

  sendDeleteEvent(event: Group) {
    this.deleteLoopGroup.emit(event);
  }

  sendEvent(event: any) {
    this.addLoopGroupLGComponent.emit(event);
  }

  createNewGroup(originalGroup: LoopGroup) {
    const newGroup = new LoopGroup({
      class: Names.GROUP,
      name: originalGroup.name,
      label: originalGroup.label,
      comparationId: GenerateComparationId(),
      creationTime: originalGroup.creationTime,
      updateTime: originalGroup.updateTime,
      parent: originalGroup.parent,
      isCreated: true,
      children: [],
      hidden: originalGroup.hidden
    });
    for (const child of originalGroup.children) {
      newGroup.children.push(this.checkType(child, newGroup) as BaseEntity);
    }
    return newGroup;
  }

  createQuestion(question: any, questionParent: any) {
    const newQuestion = ResolveQuestionType(question, this.translateService, questionParent);
    newQuestion.comparationId = GenerateComparationId();
    newQuestion.parent = questionParent;

    return newQuestion;
  }

  checkType(childEntity: any, entityParent: any): any {
    if (childEntity instanceof QuestionBase) {
      return this.createQuestion(childEntity, entityParent);
    } else if (childEntity instanceof DescriptionText) {
      return this.createTextDescription(childEntity, entityParent);
    } else if(childEntity instanceof Files) {
      return this.createFiles(childEntity, entityParent);
    } else if (childEntity instanceof Group) {
      const newGroup = new Group({
        class: Names.GROUP,
        name: childEntity.name,
        label: childEntity.label,
        comparationId: childEntity.comparationId,
        creationTime: childEntity.creationTime,
        updateTime: childEntity.updateTime,
        children: [],
        repeatable: childEntity.repeatable,
        isCreated: childEntity.isCreated,
        parent: entityParent,
        hidden: childEntity.hidden
      });
      newGroup.children = [];
      for (const child of childEntity.children) {
        newGroup.children.push(this.checkType(child, newGroup) as BaseEntity);
      }
      return newGroup;
    }
    return null;
  }

  createFiles(files: Files, parent: LoopGroup): Files {
    const filesInstance = new Files({
      class: files.class,
      updateTime: new Date(),
      creationTime:  new Date(),
      valid: true,
      children: [],
      hidden: files.hidden,
      name: files.name,
      label: files.label,
      comparationId: files.comparationId,
      parent,
      path: files.path,
      description: files.description,
      required: files.required
    });
    filesInstance.category = files.category;
    return filesInstance;
  }
  createTextDescription(textDescription: any, newParent: any): DescriptionText {

    return new DescriptionText({
      class: Names.DESCRIPTION_TEXT,
      comparationId: textDescription.comparationId,
      creationTime: textDescription.creationTime,
      updateTime: textDescription.updateTime,
      createdBy: textDescription.createdBy,
      parent: newParent,
      updatedBy: textDescription.updatedBy,
      name: textDescription.name,
      label: textDescription.label,
      hidden: textDescription.hidden,
      fieldName: textDescription.fieldName,
      description: textDescription.description,
      path: textDescription.path,
      valid: true
    });
  }
}
