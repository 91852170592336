import {IFormOptions} from './interfaces/iform-options.model';
import {BaseEntity} from './base-entity.model';
import {Category} from './category.model';

export class Form extends BaseEntity {
  updatedBy: any;
  version: any;
  organizationId: any;
  description: string;
  webserviceCalls: any;
  children: Category[];

  constructor(options: IFormOptions) {
    super(options);
    this.updatedBy = options.updatedBy || 0;
    this.version = options.version || 0;
    this.organizationId = options.organizationId || 0;
    this.description = options.description || '';
    this.webserviceCalls = options.webserviceCalls || [];
  }

}
