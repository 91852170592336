import {IMultiCheckOptions} from './interfaces/imulti-check-options.model';
import {QuestionBase} from './question-base.model';
import {Names} from './names';
import {Options} from './options';

export class MultiCheckBoxQuestion extends QuestionBase<string> {
  controlType = 'multicheck';
  value: { name: string, label: string, value?: any, checked: boolean, description?: string, children: any[] }[] = [];
  cssTag = 'checkbox';

  constructor(options: IMultiCheckOptions) {
    super(options);
    this.value = options[Options.VALUE] || [];
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  setChildren(question: any): void {
    this.value = this.getMultipleSelectionValues(question);
    this.children = this.getMultipleSelectionValues(question);
  }

  getMultipleSelectionValues(question) {
    const answers = question.children;
    const resultValues = [];
    for (const answer of answers) {
      const answerName = answer.name;
      const answerLabel = answer.label;
      const answerValue = answer.label;
      const answerDescription = answer.description;
      const generatedAnswer = {
        name: answerName,
        label: answerLabel,
        value: answerValue,
        checked: false,
        description: answerDescription,
        children: []
      };
      if (answer.children.length > 0) {
        generatedAnswer.children = this.getCheckboxSubanswers(answer.children);
      }
      resultValues.push(generatedAnswer);
    }
    return resultValues;
  }

  getCheckboxSubanswers(children: any[]): any[] {
    const subanswers = [];
    for (const child of children) {
      const subanswerName = child.name;
      const subanswerLabel = child.label;
      const subanswerDescription = child.description;
      const subanswerValue = child.label;
      const subanswerChecked = false;
      const subanswer = {
        name: subanswerName,
        label: subanswerLabel,
        value: subanswerValue,
        checked: subanswerChecked,
        description: subanswerDescription,
        children: []
      };
      if (child.children.length > 0) {
        subanswer.children = this.getCheckboxSubanswers(child.children);
      }
      subanswers.push(subanswer);
    }
    return subanswers;
  }

  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueAnswer')) {
      this.insertDefaultValueCheckBox(this, question.defaultValueAnswer);
    }
  }

  insertDefaultValueCheckBox(question, defaultValue): void {
    for (const value of question.value) {
      if (value.name === defaultValue) {
        value.checked = true;
        break;
      }
    }
  }

  setCssClass(): void {
    if (this.isValid()) {
      this.cssTag = Names.CSS_MULTICHECK_QUESTION_VALID;
    } else {
      this.cssTag = Names.CSS_MULTICHECK_QUESTION_INVALID;
    }
  }

  isValid(): boolean {
    if (this.required) {
      for (const answer of this.value) {
        if (answer.checked === true && (answer.children.length === 0 || this.checkboxSubanswersValidity(answer.children))) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  getAnswerLabel(): any[] {
    return this.getCheckboxAnswerLabels();
  }

  getCheckboxAnswerLabels() {
    const values = [];
    for (const value of this.value) {
      if (value.checked === true) {
        if (value.children.length === 0) {
          values.push(value.label);
        } else {
          for (const valueChildren of value.children) {
            if (valueChildren.checked === true) {
              values.push(valueChildren.label);
            }
          }
        }
      }
    }
    return values;
  }

  private checkboxSubanswersValidity(subanswers): boolean {
    let valid = false;
    for (let i = 0; i < subanswers.length; i++) {
      if (subanswers[i].checked === true) {
        if (subanswers[i].children.length > 0) {
          valid = this.checkboxSubanswersValidity(subanswers[i].children);
          if (valid === false) {
            return false;
          }
        } else {
          valid = true;
        }
        if (i === subanswers.length - 1) {
          return true;
        }
      }

    }
    return valid;
  }
}
