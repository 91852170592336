import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionBase} from '../../../../models/question-base.model';
import {Category} from '../../../../models/category.model';
import {TextboxQuestion} from '../../../../models/textbox-question.model';
import {RadioButtonQuestion} from '../../../../models/radio-button-question.model';
import {RadioButtonAnswer} from '../../../../models/radio-button-answer';
import {ComponentHelper} from '../../../../helpers/component-helper';


@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent implements OnInit, DoCheck {
  @Input() question: QuestionBase<string> | null = null;
  @Input() readOnly: boolean = false;

  imageUrl: any = null;
  data: any = null;
  width: number = 0;
  height: number = 0;

  @Output() updateAnswer = new EventEmitter();

  questionHint: string = '';
  previousValue: any;
  currentValue: any;

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor() {
  }

  ngOnInit() {
    if (!this.question) {
      throw new Error('Question not found');
    }
    if (this.helper.isTextboxQuestion(this.question) &&
        this.helper.isTable(this.question.parent.parent)) {
      (this.question as TextboxQuestion).setStartCss();
    }
    this.isValid();
    this.updateAnswer.emit([this.question, false]);
    if (this.question) {
      if (this.helper.isMultiCheckboxQuestion(this.question)) {
        this.previousValue = this.question.value;
        this.currentValue = JSON.parse(JSON.stringify(this.question.value));
      } else {
        this.previousValue = this.question.value;
        this.currentValue = this.question.value;
      }
      if (this.question.image) {
        if (this.question.image.imageUrl) {
          this.imageUrl = this.question.image.imageUrl;
          this.width = this.question.image.width;
          this.height = this.question.image.height;
        }
        if (this.question.image.data) {
          this.data = this.question.image.data;
          this.width = this.question.image.width;
          this.height = this.question.image.height;
        }
      }
    }
  }

  ngDoCheck() {
    if (!this.question) {
      throw new Error('Question not found');
    }
    if (this.question.highlighted) {
      this.question.setQuestionHighlightedCss();
    }
    if (!this.helper.isRadioButtonQuestion(this.question) && !this.helper.isMultiCheckboxQuestion(this.question)) {
      this.previousValue = this.currentValue;
      this.currentValue = this.question.value;
      if (this.previousValue !== this.currentValue) {
        if (this.question.value !== undefined) {
          this.isValid();
          this.setCssClass(this.question);
          this.checkValidityOfPreviousQuestions();
          this.updateAnswer.emit([this.question, true]);
        }
      }
    }
  }

  isValid(parentQuestion?: QuestionBase<string>) {
    let question = parentQuestion ? parentQuestion : this.question;
    if (!question) {
      throw new Error('Question not found');
    }
    const isValid = question.isValid();
    question.valid = isValid;
    return isValid;
  }

  updateRadioAnswer(answer: RadioButtonAnswer, parentQuestion?: QuestionBase<string>) {
    let question: QuestionBase<string>;
    if (parentQuestion) {
      question = this.findValue(this.question.children, parentQuestion.name);
    } else {
      question = this.question;
    }
    if (!question) {
      throw new Error('Question not found');
    }
    question.children.forEach(questionAnswer => {
      const radioButtonAnswer: RadioButtonAnswer = questionAnswer as RadioButtonAnswer;
      if (answer.name === radioButtonAnswer.name) {
        radioButtonAnswer.checked = true;
        // Parent selected, but children not.
        radioButtonAnswer.children.forEach(child => {
          child.checked = false;
        });
      } else {
        if (radioButtonAnswer.children.includes(answer)) {
          // Children are selected, parent must be also.
          radioButtonAnswer.checked = true;
        } else {
          // Nothing selected, unselect parent and children
          radioButtonAnswer.checked = false;
          radioButtonAnswer.children.forEach(child => {
            child.checked = false;
          });
        }
      }
    });

    this.previousValue = this.currentValue;
    if (this.helper.isRadioButtonQuestion(question)) {
      (question as RadioButtonQuestion).realValue = answer.name;
      this.currentValue = (question as RadioButtonQuestion).realValue;
    } else {
      this.currentValue = answer.name
    }

    if (this.previousValue !== this.currentValue) {
      if (answer.name) {

        if (parentQuestion) {
          const checkedFields = question.children.filter(i => (i as any).checked === true);
          if (checkedFields.length === 1) {
            question.valid = true;
          } else {
            question.valid = false;
          }

          while (question.parent.name !== this.question.name) {
            question = this.findValue(this.question.children, question.parent.name);
            const checkedFields = question.children.filter(i => (i as any).checked === true);
            if (checkedFields.length === 1) {
              question.valid = true;
            } else {
              question.valid = false;
            }
          }
        }

        this.isValid(this.question);
        this.updateAnswer.emit([this.question, true]);
      }
    }
    this.setCssClass(this.question);
    this.checkValidityOfPreviousQuestions();
  }

  updateCheckBoxAnswers(name: string): void {
    if (!this.question) {
      throw new Error('Question not found');
    }
    this.previousValue = JSON.parse(JSON.stringify(this.currentValue));
    if (this.question.value) {
      for (const questionValue of this.question.value) {
        if (questionValue.name === name) {
          questionValue.checked = questionValue.checked === false;
        }
      }
    }
    this.currentValue = this.question.value;
    this.isValid();
    this.updateAnswer.emit([this.question, true]);
    this.setCssClass(this.question);
    this.checkValidityOfPreviousQuestions();
    return this.question.value;
  }

  updateCheckboxSubanswer() {
    this.isValid();
    if (this.checkCheckBoxModification()) {
      if (this.question && this.question.value) {
        this.isValid();
        if (this.question.valid) {
          this.updateAnswer.emit([this.question, true]);
        }
      }
    }
    this.setCssClass(this.question);
    this.checkValidityOfPreviousQuestions();
  }

  checkCheckBoxModification() {
    if (!this.question) {
      throw new Error('Question not found');
    }
    for (let i = 0; i < this.question.value.length; i++) {
      if (this.previousValue && this.previousValue[i].checked !== this.currentValue[i].checked) {
        this.previousValue = JSON.parse(JSON.stringify(this.currentValue));
        this.currentValue = JSON.parse(JSON.stringify(this.question.value));
        return true;
      }
      if (this.question.value[i].children.length > 0) {
        for (let j = 0; j < this.question.value[i].children.length; j++) {
          const currentSubanswer = this.question.value[i].children[j];
          const previousSubanswer = this.previousValue[i].children[j];
          if (previousSubanswer.checked !== currentSubanswer.checked) {
            this.previousValue = this.currentValue;
            this.currentValue = JSON.parse(JSON.stringify(this.question.value));
            return true;
          }
        }
      }
    }
    this.previousValue = this.currentValue;
    this.currentValue = this.question.value;
    return false;
  }

  focusOut() {
    if (this.helper.isTextboxQuestion(this.question)) {
      this.questionHint = '';
    }
  }

  focus() {
    this.checkValidityOfPreviousQuestions();
    if (this.helper.isTextboxQuestion(this.question)) {
      this.questionHint = (this.question as TextboxQuestion).createQuestionHint();
    }
  }

  checkValidityOfPreviousQuestions() {
    const category = this.getCategory();
    for (const childPath of category.childrenPaths) {
      const question = childPath;
      if (!question.hidden && !question.valid) {
        this.setCssClass(question);
      }
      if (this.question) {
        if (question.comparationId === this.question.comparationId) {
          break;
        }
      }
    }
  }

  private getCategory(): Category {
    if (!this.question) {
      throw new Error('Question not found');
    }
    let found = false;
    let parent = this.question.parent;
    while (!found) {
      if (parent.hasOwnProperty('childrenPaths')) { // childrenPaths has an arrays of references to each question of the category
        found = true;
        return parent as Category;
      }
      parent = parent.parent;
    }
    throw Error('Category not found');
  }

  private setCssClass(question: QuestionBase<string> | null): void {
    if (question == null) {
      return;
    }
    question.setCssClass();
  }

  getPlaceholder(): string {
    if(!this.question) {
      throw new Error('Question not found');
    }
    if (this.helper.isTable(this.question.parent.parent)) {
      if (this.question && this.question.answerSubformat) {
        switch (this.question.answerSubformat) {
          case ('NUMBER'): {
            return '0';
          }
          case ('FLOAT'): {
            return '0.0';
          }
          case ('POSITIVE_NUMBER'): {
            return '0.0';
          }
          case ('NEGATIVE_NUMBER'): {
            return '-0';
          }
        }
      }
    } else {
      return '';
    }
    return '';
  }

  // In the html shows the red asterisk
  mandatoryInvalid(): boolean {
    if (this.question) {
      return !this.question.valid && this.question.required && !this.helper.isTable(this.question.parent.parent);
    }
    return true;
  }

  // In the html shows the gry asterisk
  mandatoryValid(): boolean {
    if (this.question) {
      return this.question.valid && this.question.required && !this.helper.isTable(this.question.parent.parent);
    }
    return true;
  }

  // In the html shows the hint
  showHint(): boolean {
    if (this.question) {
      return this.question.controlType === 'textbox' && this.question.answerFormat !== '' &&
        this.question.answerSubformat !== '' && this.questionHint !== '' && !this.helper.isTable(this.question.parent.parent);
    }
    return true;
  }

  findValue(arr, val): any {
    for(let obj of arr){
      if (obj.name === val) {
        return obj;
      }
      if(obj.children){
        let result = this.findValue(obj.children, val);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

}
