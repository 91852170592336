import {Injectable} from '@angular/core';
import {Group} from '../models/group.model';
import {Category} from '../models/category.model';
import {Table} from '../models/table';
import {IRadioButtonOptions} from '../models/interfaces/iradio-button-options.model';
import {SystemField} from '../models/system-field.model';
import {BaseEntity} from '../models/base-entity.model';
import {RadioButtonQuestion} from '../models/radio-button-question.model';
import {Image} from '../models/image.model';
import {Names} from '../models/names';
import {DropdownQuestion} from '../models/dropdown-question.model';
import {IBaseQuestionOptions} from '../models/interfaces/ibase-question-options.model';
import {SliderQuestion} from '../models/slider-question.model';
import {QuestionBase} from '../models/question-base.model';
import {MultiCheckBoxQuestion} from '../models/multi-check-box-question.model';
import {TextAreaQuestion} from '../models/text-area-question.model';
import {TextboxQuestion} from '../models/textbox-question.model';
import {Files} from '../models/files.model';
import {DescriptionText} from '../models/description-text.model';
import {TranslateService} from '@ngx-translate/core';
import {Form} from '../models/form.model';
import {LoopGroup} from '../models/loop-group.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  public static QUESTION = Names.QUESTION;
  public static GROUP = Names.GROUP;
  public static SYSTEM_FIELD = Names.SYSTEM_FIELD;
  public static DESCRIPTION_TEXT = Names.DESCRIPTION_TEXT;
  public static FILES = Names.FILES;

  public static TEXT_AREA = Names.TEXT_AREA;
  public static INPUT = Names.INPUT;
  public static RADIO_BUTTON = Names.RADIO_BUTTON;
  public static CHECKBOX = Names.CHECKBOX;
  public static DROPDOWN = Names.DROPDOWN;
  public static SLIDER = Names.SLIDER;

  public childrenPaths: any;

  constructor(private translate: TranslateService) {
  }


  getFlows(form) {
    return form.flows;
  }

  /**
   * Returns a superset of the Json form.
   * @param form Json with the specification of the form from Webforms
   */
  createForm(form): Form {
    const newForm = new Form({
      class: form.class,
      name: form.name,
      label: form.label,
      comparationId: form.comparationId,
      creationTime: form.creationTime,
      updateTime: form.updateTime,
      children: this.resolveCategoriesFromForm(form),
      updatedBy: 0,
      hidden: form.hidden,
      version: form.version,
      organizationId: form.organizationId,
      description: form.description,
      webserviceCalls: form.webserviceCalls
    });
    if (form.hasOwnProperty('image')) {
      newForm.image = new Image({
        class: form.image.class,
        comparationId: form.image.comparationId,
        creationTime: form.image.creationTime,
        updateTime: form.image.updateTime,
        createdBy: form.image.createdBy,
        width: form.image.width,
        height: form.image.height,
        imageUrl: form.image.imageUrl,
        data: form.image.data
      });
    }
    return this.updateIsHiddenValues(newForm) as Form;
  }

  resolveCategoriesFromForm(form: any): any {
    const cats: any[] = [];
    for (const children of form.children) {
      this.childrenPaths = [];
      cats.push(this.createCategory(children));
    }
    return cats;
  }

  createCategory(category): Category {
    const categoryChildren = this.categoryChildren(category);
    const childrenPathsSrc = this.childrenPaths;
    const newCategory = new Category({
      class: category.class,
      name: category.name,
      label: category.label,
      comparationId: category.comparationId,
      creationTime: category.creationTime,
      updateTime: category.updateTime,
      createdBy: category.createdBy,
      updatedBy: category.updatedBy,
      children: categoryChildren,
      hidden: true,
      isHidden: category.hidden,
      childrenPaths: childrenPathsSrc
    });
    if (category.hasOwnProperty('image')) {
      newCategory.image = new Image({
        class: category.image.class,
        comparationId: category.image.comparationId,
        creationTime: category.image.creationTime,
        updateTime: category.image.updateTime,
        createdBy: category.image.createdBy,
        width: category.image.width,
        height: category.image.height,
        imageUrl: category.image.imageUrl,
        data: category.image.data
      });
    }
    return newCategory;
  }

  categoryChildren(category) {
    let entities: any = [];
    for (const entity of category.children) {
      if (entity !== undefined && entity.class === QuestionService.QUESTION) {
        const directQuestion = this.getQuestion(entity, [category.name], category);
        entities = entities.concat(directQuestion);
        this.childrenPaths.push(directQuestion.path);
      } else if (entity.class === QuestionService.SYSTEM_FIELD) {
        const systemfield = this.createSystemField(entity, category, [category.name]);
        entities = entities.concat(systemfield);
        this.childrenPaths.push(systemfield.path);
      } else if (entity.class === QuestionService.DESCRIPTION_TEXT) {
        const descText = this.createTextDescription(entity, category, [category.name]);
        entities = entities.concat(descText);
        this.childrenPaths.push(descText.path);
      } else if (entity.class === QuestionService.FILES) {
        const filesSelect = this.createFileSelector(entity, category, [category.name]);
        entities = entities.concat(filesSelect);
        this.childrenPaths.push(filesSelect.path);
      } else {
        const path = [category.name];
        const entityName = entity.name;
        path.push(entityName);
        if (entity.isTable) {
          const newTable = this.createTable(entity, category, path);
          entities = entities.concat(newTable);
        } else {
          const newGroup = this.createGroup(entity, category, path);
          entities = entities.concat(newGroup);
        }
      }
    }
    return entities;
  }

  createFileSelector(fileSelectorSpecification, parentElement, path): Files {
    const selectorFilePath: string[] = [].concat(path);
    selectorFilePath.push(fileSelectorSpecification.name);
    const filesSelector = new Files({
      class: fileSelectorSpecification.class,
      name: fileSelectorSpecification.name,
      label: fileSelectorSpecification.label,
      comparationId: fileSelectorSpecification.comparationId,
      creationTime: fileSelectorSpecification.creationTime,
      updateTime: fileSelectorSpecification.updateTime,
      parent: parentElement,
      hidden: fileSelectorSpecification.hidden,
      required: fileSelectorSpecification.mandatory,
      isHidden: fileSelectorSpecification.isHidden,
      path: selectorFilePath,
      description: fileSelectorSpecification.description
    });
    return filesSelector;
  }

  createTable(table, parentElement, groups): Group {
    return new Table({
      class: table.class,
      name: table.name,
      label: table.label,
      comparationId: table.comparationId,
      creationTime: table.creationTime,
      updateTime: table.updateTime,
      repeatable: table.repeatable,
      children: this.getGroupChildren(table, groups),
      parent: parentElement,
      hidden: true,
      isHidden: table.hidden,
      isCreated: false
    });
  }

  createGroup(group, parentElement, paths): Group {
    const options = {
      class: group.class,
      name: group.name,
      label: group.label,
      comparationId: group.comparationId,
      creationTime: group.creationTime,
      updateTime: group.updateTime,
      repeatable: group.repeatable,
      children: this.getGroupChildren(group, paths),
      parent: parentElement,
      hidden: group.hidden,
      isHidden: group.isHidden,
      isCreated: false,
      numberOfColumn: group.numberOfColumn
    }

    let newGroup;
    if (group.repeatable) {
      newGroup = new LoopGroup(options);
    } else {
      newGroup = new Group(options);
    }
    newGroup.setHasDynamicLabel();
    return newGroup;
  }

  createSystemField(systemField, parentElement, path): SystemField {
    const systemFieldPath: string[] = [].concat(path);
    systemFieldPath.push(systemField.name);
    const newSystemField = new SystemField({
      class: systemField.class,
      comparationId: systemField.comparationId,
      creationTime: systemField.creationTime,
      updateTime: systemField.updateTime,
      createdBy: systemField.createdBy,
      updatedBy: systemField.updatedBy,
      parent: parentElement,
      name: systemField.name,
      label: systemField.label,
      hidden: false,
      fieldName: systemField.fieldName,
      value: undefined,
      valid: true,
      path: systemFieldPath

    });
    newSystemField.path = systemFieldPath;
    return newSystemField;
  }

  createTextDescription(textDescription, parentElement, path): DescriptionText {
    const textDescriptionPath : string[] = [].concat(path);
    textDescriptionPath.push(textDescription.name);
    const newTextDescription = new DescriptionText({
      class: textDescription.class,
      comparationId: textDescription.comparationId,
      creationTime: textDescription.creationTime,
      updateTime: textDescription.updateTime,
      createdBy: textDescription.createdBy,
      parent: parentElement,
      updatedBy: textDescription.updatedBy,
      name: textDescription.name,
      label: textDescription.label,
      hidden: true,
      isHidden: textDescription.hidden,
      fieldName: textDescription.fieldName,
      description: textDescription.description,
      path: textDescriptionPath,
      valid: true

    });
    newTextDescription.setHasDynamicLabel();
    newTextDescription.path = textDescriptionPath;
    return newTextDescription;
  }

  getGroupChildren(group, previousPath) {
    let path = [].concat(previousPath);
    let groupChildrens: any = [];
    // const questions: QuestionBase<any>[] = [];
    for (const entity of group.children) {
      if (entity.class !== undefined && entity.class === QuestionService.QUESTION) {
        path = previousPath;
        const question = this.getQuestion(entity, path, group);
        groupChildrens = groupChildrens.concat(question);
        this.childrenPaths.push(question.path);
      } else if (entity.class === QuestionService.SYSTEM_FIELD) {
        path = previousPath;
        const systemField = this.createSystemField(entity, group, path);
        groupChildrens = groupChildrens.concat(systemField);
        this.childrenPaths.push(systemField.path);
      } else if (entity.class === QuestionService.DESCRIPTION_TEXT) {
        path = previousPath;
        const descriptionText = this.createTextDescription(entity, group, path);
        groupChildrens = groupChildrens.concat(descriptionText);
        this.childrenPaths.push(descriptionText.path);
      } else if (entity.class === QuestionService.FILES) {
        path = previousPath;
        const filesSelect = this.createFileSelector(entity, group, path);
        groupChildrens = groupChildrens.concat(filesSelect);
        this.childrenPaths.push(filesSelect.path);
      } else if (entity.class !== undefined && entity.class === QuestionService.GROUP) {
        path = previousPath;
        path = path.concat(entity.name);
        if (entity.isTable) {
          const newTable = this.createTable(entity, group, path);
          groupChildrens.push(newTable);
        } else {
          const newGroup = this.createGroup(entity, group, path);
          groupChildrens.push(newGroup);
        }
      }
    }
    return groupChildrens;
  }

  getQuestion(question, previousPath, parent): QuestionBase<any> {
    const path: string[] = [].concat(previousPath);
    path.push(question.name);
    const directQuestion = this.resolveQuestionType(question, path, parent);
    directQuestion.isHidden = question.hidden;
    directQuestion.image = this.getImage(question.image);
    directQuestion.multipleImages = question.children;
    directQuestion.setHasDynamicLabel();
    return directQuestion;
  }

  getImage(imageDefinition): Image | null {
    if (imageDefinition !== undefined) {
      const imageObject = new Image({
        class: imageDefinition.class,
        comparationId: imageDefinition.comparationId,
        creationTime: imageDefinition.creationTime,
        updateTime: imageDefinition.updateTime,
        createdBy: imageDefinition.createdBy,
        width: imageDefinition.width,
        height: imageDefinition.height,
        imageUrl: imageDefinition.imageUrl,
        data: imageDefinition.data
      });
      return imageObject;
    } else {
      return null;
    }
  }

  // Depending on the answerType return the question filled with its values
  resolveQuestionType(question, questionPath, parentElement): QuestionBase<any> {

    const answerType = question.answerType;

    const questionComparationId = question.comparationId;
    const iQuestionOptions: IBaseQuestionOptions = {
      name: question.name,
      label: question.label,
      required: question.mandatory,
      hidden: true,
      comparationId: questionComparationId,
      creationTime: question.creationTime,
      updateTime: question.updateTime,
      path: questionPath,
      parent: parentElement,
      description: question.description
    };

    if (answerType === QuestionService.TEXT_AREA) {
      const textAreaQuestion = new TextAreaQuestion(iQuestionOptions);
      textAreaQuestion.setInitialAnswers(question);
      return textAreaQuestion;
    } else if (answerType === QuestionService.DROPDOWN) {
      const dropdownQuestion = new DropdownQuestion(iQuestionOptions);
      dropdownQuestion.setAnswers(question);
      dropdownQuestion.setInitialAnswers(question);
      return dropdownQuestion;
    } else if (answerType === QuestionService.RADIO_BUTTON) {
      const iRadioOptions = iQuestionOptions as IRadioButtonOptions;
      iRadioOptions.horizontal = question.horizontal;
      const radioButtonQuestion = new RadioButtonQuestion(iRadioOptions);
      radioButtonQuestion.setChildren(question);
      radioButtonQuestion.setInitialAnswers(question);
      return radioButtonQuestion;
    } else if (answerType === QuestionService.CHECKBOX) {
      const checkBoxQuestion = new MultiCheckBoxQuestion(iQuestionOptions);
      checkBoxQuestion.setChildren(question);
      checkBoxQuestion.setInitialAnswers(question);
      return checkBoxQuestion;
    } else if (answerType === QuestionService.SLIDER) {
      const sliderQuestion = new SliderQuestion(iQuestionOptions);
      sliderQuestion.setMinValue(question);
      sliderQuestion.setMaxValue(question);
      sliderQuestion.setStep(question);
      sliderQuestion.setInitialAnswers(question);
      sliderQuestion.setChildren(question);
      return sliderQuestion;
    } else if (answerType === QuestionService.INPUT) {
      const inputQuestion = new TextboxQuestion(iQuestionOptions, this.translate);
      inputQuestion.setFormat(question);
      inputQuestion.setInitialAnswers(question);
      return inputQuestion;
    } else {
      // In case there is a non defined type
      throw new Error('Non defined question type:' + answerType);
    }
  }

  updateIsHiddenValues(entity: BaseEntity) {
    const newEntity = entity;
    let allHidden = true;
    if (newEntity instanceof QuestionBase) {
      allHidden = newEntity.isHidden;
    } else {
      for (let baseEntity of newEntity.children) {
        baseEntity = this.updateIsHiddenValues(baseEntity);
        if (!baseEntity.getIsHidden()) {
          allHidden = false;
        }
      }
      if (newEntity.children.length === 0 &&
          newEntity.isHidden !== undefined) {
        allHidden = newEntity.isHidden;
      }
    }
    if (allHidden) {
      newEntity.isHidden = true;
    }
    return newEntity;
  }

}
