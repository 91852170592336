import {IImageOptions} from './interfaces/iimage-options.model';

export class Image {

  class: string;
  comparationId: string;
  creationTime: any;
  updateTime: any;
  createdBy: any;
  width: number;
  height: number;
  imageUrl?: string;
  data?: any;

  constructor(options: IImageOptions) {
    this.class = options.class || '';
    this.comparationId = options.comparationId || '';
    this.creationTime = options.creationTime || '';
    this.updateTime = options.updateTime || '';
    this.createdBy = options.createdBy || 0;
    this.width = options.width || 0;
    this.height = options.height || 0;
    this.imageUrl = options.imageUrl || undefined;
    this.data = options.data || undefined;
  }
}
