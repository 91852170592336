import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {LogService} from './log.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  transporter;
  private mailServer = environment.mailServer;

  constructor(private logger: LogService) {
    // this.transporter = nodemailer.createTransport({
    //   host: this.mailServer.host,
    //   port: this.mailServer.port,
    //   secure: this.mailServer.secure,
    //   auth: {
    //     user: this.mailServer.auth.user,
    //     pass: this.mailServer.auth.pass
    //   }
    // });
    this.verify();
  }


  public sendEmail(content: string) {
    const message = {
      from: this.mailServer.from,
      to: this.mailServer.to,
      subject: 'Contact Form',
      text: content,
      html: ''
    };

    this.transporter.sendMail(message, (error, info) => {
      if (error) {
        return this.logger.error(error);
      }
      this.logger.info('Message sent: %s', info.messageId, info);
    });
  }

  public verify() {
    // this.transporter.verify((error) => {
    //   if (error) {
    //     this.logger.error('Error verifying the email connection: ', error);
    //   } else {
    //     this.logger.info('Email server is ready!');
    //   }
    // });
  }
}
