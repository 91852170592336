import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Names} from '../../../../models/names';
import {Group} from '../../../../models/group.model';
import {ComponentHelper} from '../../../../helpers/component-helper';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent {

  @Input() group: Group | null = null;
  @Input() readOnly: boolean = false;

  descriptionTextClass: string = Names.DESCRIPTION_TEXT;

  @Output() updateAnswerGroup = new EventEmitter();
  @Output() addLoopGroupComponent = new EventEmitter();
  @Output() deleteLoopGroupComponent = new EventEmitter();

  get helper(): typeof ComponentHelper {
    return ComponentHelper;
  }

  constructor() {
  }

  // TODO(jhortelano): duplicated code
  updateAnswerToParent(event: any): void {
    if (!this.group) {
      return;
    }
    if (event[0].valid === true) {
      for (const child of this.group.children) {
        if (child.hidden === false) {
          if (child.valid === false) {
            this.group.valid = false;
            this.updateAnswerGroup.emit(event);
            return;
          }
        }
      }
      this.group.valid = true;
    } else {
      this.group.valid = false;
    }
    this.updateAnswerGroup.emit(event);
  }

  addLoopGroups(event: any): void {
    this.addLoopGroupComponent.emit(event);
  }

  sendEvent(event: any): void {
    this.addLoopGroupComponent.emit(event);
  }

  deleteLoopGroups(event: any): void {
    this.deleteLoopGroupComponent.emit(event);
  }

  sendDeleteEvent(event: any): void {
    this.deleteLoopGroupComponent.emit(event);
  }

}
