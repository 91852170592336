import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FormRunnerComponent} from './form-runner.component';
import {DynamicFormQuestionComponent} from '../dynamic-form-question/dynamic-form-question.component';
import {FormComponent} from '../form/form.component';
import {GroupComponent} from '../group/group.component';
import {LoopGroupComponent} from '../loop-group/loop-group.component';
import {CategoryComponent} from '../category/category.component';
import {DescriptionTextComponent} from '../description-text/description-text.component';
import {CheckboxSubanswersComponent} from '../checkbox-subanswers/checkbox-subanswers.component';
import {RadioSubanswersComponent} from '../radio-subanswers/radio-subanswers.component';
import {TableComponent} from '../table/table.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SafeHtmlPipe} from '../../../../pipes/safe-html.pipe';
import {FilesComponent} from '../files/files.component';
import {QuestionService} from '../../../../services/question.service';
import {WebcallsService} from '../../../../services/webcalls.service';
import {ConditionService} from '../../../../services/condition.service';
import {SubmitService} from '../../../../services/submit.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    FormRunnerComponent,
    DynamicFormQuestionComponent,
    FormComponent,
    GroupComponent,
    LoopGroupComponent,
    CategoryComponent,
    DescriptionTextComponent,
    CheckboxSubanswersComponent,
    RadioSubanswersComponent,
    TableComponent,
    FilesComponent,
    SafeHtmlPipe
  ],
  exports: [
    FormRunnerComponent
  ],
  providers: [
    QuestionService,
    SubmitService,
    ConditionService,
    WebcallsService
  ],
})

export class FormRunnerModule {
}
