import {BaseEntity} from './base-entity.model';
import {IBaseQuestionOptions} from './interfaces/ibase-question-options.model';
import {Names} from './names';

export abstract class QuestionBase<T> extends BaseEntity {
  class = Names.QUESTION;
  value: any;
  required: boolean;
  controlType: string;
  answerFormat: string;
  answerSubformat: string;
  path: string[];
  valid: boolean;
  parent: any;
  isEditable = true;
  highlighted = false;
  questionHighlightedCss = 'highlight';
  cssTag: string;
  horizontal = false;


  protected constructor(options: IBaseQuestionOptions) {
    super(options);
    this.value = options.value;
    this.required = options.required;
    this.controlType = options.controlType || '';
    this.answerFormat = options.answerFormat || '';
    this.answerSubformat = options.answerSubformat || '';
    this.path = options.path || [];
    this.valid = options.valid || false;
    this.parent = options.parent || '';
  }

  show(): void {
    this.hidden = !!this.isHidden;
  }

  setQuestionHighlightedCss() {
    if (this.highlighted) {
      this.questionHighlightedCss = 'highlight questionHighlighted';
    }
  }


  /**
   * Set the css class depending on the validity of the question.
   */
  abstract setCssClass(): void;

  /**
   * Returns the validity of the questions checking its validators
   */
  abstract isValid(): boolean;

  /**
   * Set the css class depending on the validity of the question.
   */
  abstract getAnswerLabel(): any[];

  /**
   *
   * @param value Set the value to the question
   */
  abstract setValue(value: any): void;

  abstract setInitialAnswers(question: any): void;

}
