import {Names} from './names';
import {Table} from './table';
import {QuestionBase} from './question-base.model';
import {ITextBoxOptions} from './interfaces/itext-box-options.model';
import {TranslateService} from '@ngx-translate/core';
import {Options} from './options';

export class TextboxQuestion extends QuestionBase<string> {
  controlType = 'textbox';
  type: any;
  cssTag = 'fr-answer input';

  constructor(options: ITextBoxOptions, private translate: TranslateService) {
    super(options);
    this.type = options[Options.TYPE] || undefined;
    this.answerFormat = options[Options.ANSWER_FORMAT] || '';
    this.answerSubformat = options[Options.ANSWER_SUBFORMAT] || '';
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  setFormat(question: any): void {
    if (question.hasOwnProperty('answerFormat')) {
      this.answerFormat = question.answerFormat;
      this.answerSubformat = question.answerSubformat;
    }
    if (question.answerFormat === 'DATE') {
      this.type = 'DATE';
    }
  }

  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueString')) {
      this.value = question.defaultValueString;
    }
    if (question.hasOwnProperty('defaultValueTime')) {
      this.value = this.defaultValueTime(question.defaultValueTime);
    }
  }

  defaultValueTime(date): string {
    const splitDate = date.split(' ', 3);
    const formattedDate = new Date(date);
    const day = splitDate[1].substring(0, 2);
    const month = formattedDate.getMonth();
    const year = formattedDate.getFullYear();
    return year + '-' + (month + 1) + '-' + day;
  }

  setStartCss() {
    if (this.parent.parent instanceof Table) {
      this.cssTag = 'fr-answer table-input';
    } else {
      this.cssTag = 'fr-answer input';
    }
  }

  setCssClass(): void {
    if (this.parent.parent instanceof Table) {
      if (this.isValid()) {
        this.cssTag = 'fr-answer table-input';
      } else {
        this.cssTag = 'fr-answer table-input';
      }
    } else {
      if (this.isValid()) {
        this.cssTag = Names.CSS_TEXTBOX_QUESTION_VALID;
      } else {
        this.cssTag = Names.CSS_TEXTBOX_QUESTION_INVALID;
      }
    }
  }

  isValid(): boolean {
    if (this.required) {
      if (this.value === undefined || this.value === '') {
        return false;
      } else {
        return this.checkAnswerValidators();
      }
    } else {
      if (this.value === undefined || this.value === '') {
        if (this.name === 'user_guard') {
          return true;
        }
        return true;
      } else {
        if (this.name === 'user_guard') {
          return this.value.length === 6;
        }
        return this.checkAnswerValidators();
      }
    }
  }

  checkAnswerValidators() {
    return this.checkAnswerFormat(this.answerFormat) && this.checkAnswerSubformat(this.answerSubformat);
  }

  checkAnswerFormat(answerFormat) {
    if (answerFormat === 'NUMBER') {
      if (!isNaN(Number(this.value))) {
        return true;
      } else if (this.value === '') {
        return false;
      } else {
        return false;
      }
    } else if (answerFormat === 'TEXT') {
      /*const re = /^[a-zA-Z0-9_.-]*$/;
      if (re.test(this.question.value)) {
        return true;
      }
      else return false;*/
      return true;

    } else if (answerFormat === 'DATE') {
      const re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

      return this.value.length === 10;
    } else if (answerFormat === 'POSTAL_CODE') {
      const re = /^[0-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      // [\\u0000-\\uFFFF]+
      // /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      return re.test(this.value);
    } else {
      return true;
    }
  }

  checkAnswerSubformat(answerSubformat) {
    if (answerSubformat === 'NUMBER') {
      if (!isNaN(Number(this.value))) {
        return true;
      } else if (this.value === '') {
        return false;
      } else {
        return false;
      }
    } else if (answerSubformat === 'FLOAT') {
      if (!isNaN(Number(this.value))) {
        return true;
      } else if (this.value === '') {
        return false;
      } else {
        return false;
      }
    } else if (answerSubformat === 'POSITIVE_NUMBER') {
      return this.value >= 0;
    } else if (answerSubformat === 'NEGATIVE_NUMBER') {
      return this.value < 0;
    } else if (answerSubformat === 'POSITIVE_FLOAT') {
      const re = /[+]?([0-9]*[.])[0-9]+/;
      return !isNaN(Number(this.value)) && this.value >= 0;
    } else if (answerSubformat === 'NEGATIVE_FLOAT') {
      const re = /[-]?([0-9]*[.])[0-9]+/;
      return !isNaN(Number(this.value)) && this.value < 0;
    } else if (answerSubformat === 'EMAIL') {
      // tslint:disable-next-line:max-line-length
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.value);
    } else if (answerSubformat === 'TEXT') {
      return true;
    } else if (answerSubformat === 'IBAN') {
      // tslint:disable-next-line:max-line-length
      const re = /^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/;
      return re.test(this.value);
    } else if (answerSubformat === 'DATE') {
      const re = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
      return this.value.length === 10;
    } else if (answerSubformat === 'DATE_PAST') {
      const re = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
      if (this.value.length === 10) {
        const date = new Date(this.value);
        const actualDate = new Date();
        const resultInMilliseconds = actualDate.getTime() - date.getTime();
        return resultInMilliseconds > 0;
      } else {
        return false;
      }

    } else if (answerSubformat === 'DATE_FUTURE') {
      const re = /^\d{2}[./-]\d{2}[./-]\d{4}$/;

      if (this.value.length === 10) {
        const dateTokens = this.getDateTokens(this.value);
        const date = new Date(this.value);
        const actualDate = new Date();
        const resultInMilliseconds = actualDate.getTime() - date.getTime();
        return resultInMilliseconds < 0;
      } else {
        return false;
      }
    } else if (answerSubformat === 'PHONE') {
      const re = /[\\+]{0,1}[0-9]{5,14}$/;
      return re.test(this.value);
    } else if (answerSubformat === 'BSN') {
      const re = /^[0-9]{9}$/;
      return this.value.length > 5;
    } else {
      return true;
    }
  }

  getDateTokens(value) {
    return value.split('-', 3);
  }

  createQuestionHint(): string {
    let hint = '';
    if (this !== undefined && this.answerSubformat !== undefined) {
      if (this.answerSubformat) {
        this.translate.get(this.answerSubformat).subscribe((res: string) => {
          hint = res;
        }, error => {
          console.log('Error translating hint:', error);
        });
      } else {
        return '';
      }
      return hint;
    }
    return hint;
  }

  getAnswerLabel(): any[] {
    return [this.value];
  }
}
