import {IRadioButtonOptions} from './interfaces/iradio-button-options.model';
import {QuestionBase} from './question-base.model';
import {DynamicAnswer} from './dynamic-answer.model';
import {Names} from './names';
import {Options} from './options';
import {RadioButtonAnswer} from './radio-button-answer';

export class RadioButtonQuestion extends QuestionBase<string> {
  controlType = 'radiobutton';
  horizontal: boolean;
  realValue: string = null;
  cssTag = 'radio';
  children: RadioButtonAnswer[];

  constructor(options: IRadioButtonOptions) {
    super(options);
    this.horizontal = options[Options.HORIZONTAL] || false;
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueAnswer')) {
      this.value = question.defaultValueAnswer;
      this.realValue = question.defaultValueAnswer;
      for (const child of this.children) {
        if (child.name === question.defaultValueAnswer) {
          child.checked = true;
        }
      }
    }
  }

  setChildren(question: any): void {
    this.children = this.getRadioButtonValues(question);
  }

  getRadioButtonValues(question) {
    const answers = question.children;
    const resultValues = [];
    for (const answer of answers) {
      const radioName = answer.name;
      const radioLabel = answer.label;
      const radioDescription = answer.description;
      const answerValue = {name: radioName, label: radioLabel, value: undefined, description: radioDescription, children: [], parent: question};
      if (answer.children.length > 0) {
        answerValue.children = this.getRadioSubanswers(answer);
      }
      if (answer.class === 'com.biit.webforms.persistence.entity.DynamicAnswer') {
        const dynamicAnswer = new DynamicAnswer({
          name: radioName,
          label: radioLabel,
          reference: answer.reference,
          description: radioDescription,
          parent: question
        });
        resultValues.push(dynamicAnswer);
      } else {
        resultValues.push(answerValue);
      }
    }
    return resultValues;
  }

  getRadioSubanswers(question) {
    const subanswers = [];
    for (const child of question.children) {
      const subanswerName = child.name;
      const subanswerLabel = child.label;
      const subanswerDescription = child.description;
      const subanswerValue = child.label;
      const subanswer = {
        name: subanswerName,
        label: subanswerLabel,
        value: subanswerValue,
        description: subanswerDescription,
        children: [],
        parent: question
      };
      if (child.children.length > 0) {
        subanswer.children = this.getRadioSubanswers(child);
      }
      subanswers.push(subanswer);
    }
    return subanswers;
  }

  setCssClass(): void {
    if (this.isValid()) {
      this.cssTag = Names.CSS_RADIO_QUESTION_VALID;
    } else {
      this.cssTag = Names.CSS_RADIO_QUESTION_INVALID;
    }
  }

  isValid(): boolean {
    if (this.required) {
      this.valid = this.checkRadioValid();
    } else {
      this.valid = true;
    }
    return this.valid;
  }

  checkRadioValid() {
    if (this.required) {
      if (this.value === undefined || this.value === '') {
        return false;
      } else {
        for (const answer of this.children) {
          if (answer.checked) {
            if (answer.children.length > 0) {
              if (this.radioSubanswersValidity(answer)) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  radioSubanswersValidity(answer) {
    for (const subanswer of answer.children) {
      if (subanswer.checked) {
        this.realValue = answer.value;
        return true;
      }
    }
    return false;
  }

  getAnswerLabel(): any[] {
    return [this.getRealValueLabel()];
  }

  getRealValueLabel(): string {
    for (const child of this.children) {
      if (child.name === this.realValue) {
        return child.label;
      } else if (child.children.length !== 0) {
        for (const subchild of child.children) {
          if (subchild.name === this.realValue) {
            return subchild.label;
          }
        }
      }
    }
    return '';
  }
}
