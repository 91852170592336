import {QuestionBase} from './question-base.model';
import {ISliderOptions} from './interfaces/islider-options.model';
import {Names} from './names';

export class SliderQuestion extends QuestionBase<string> {
  controlType = 'slider';
  minValue?: any;
  maxValue?: any;
  step?: any;
  cssTag = 'slider';

  constructor(options: ISliderOptions) {
    super(options);
    this.maxValue = options.maxValue || 1;
    this.minValue = options.minValue || 0;
    this.step = options.step || 1;
    this.children = options.children || [];
    this.value = options.value || undefined;
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  setChildren(question: any): void {
    this.children = this.getSliderValues(question);
  }

  setMaxValue(question: any): void {
    this.maxValue = this.getSliderMaxValue(question);
  }

  setMinValue(question: any): void {
    this.minValue = this.getSliderMinValue(question);
  }

  setStep(question: any): void {
    this.step = this.getSliderStep(question);
  }


  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueAnswer')) {
      this.value = question.defaultValueAnswer;
    }
  }

  setCssClass(): void {
    if (this.isValid()) {
      this.cssTag = Names.CSS_SLIDER_QUESTION_VALID;
    } else {
      this.cssTag = Names.CSS_SLIDER_QUESTION_INVALID;
    }
  }

  isValid(): boolean {
    if (this.required) {
      return !(this.value === undefined || this.value === '');
    } else {
      return true;
    }
  }

  getSliderValues(question) {
    const answers = question.children;
    const resultValues = [];
    for (const answer of answers) {
      const sliderValueName = answer.name;
      const sliderValueLabel = answer.label;
      const sliderValue = answer.label;
      const sliderValueDescription = answer.description;
      const answerValue = {
        name: sliderValueName,
        label: sliderValueLabel,
        value: sliderValue,
        description: sliderValueDescription,
        children: []
      };
      resultValues.push(answerValue);
    }
    return resultValues;
  }

  getSliderMaxValue(question) {
    if (question !== undefined) {
      return question.children[question.children.length - 1].label;
    }
    return 1;

  }

  getSliderMinValue(question) {
    return question.children[0].label || 0;
  }

  getSliderStep(question) {
    const elementsLength = question.children.length;
    if (question.children && question.children.length > 1) {
      if (question.children[1].label && question.children[0].label) {
        if ((question.children[1].label - question.children[0].label) > 0) {
          return question.children[1].label - question.children[0].label;
        }
      }
    }
    return question.children.length / elementsLength || 1;
  }

  getAnswerLabel(): any[] {
    return [this.value];
  }
}
