import {BaseEntity} from './base-entity.model';
import {IDescriptionTextOptions} from './interfaces/idescription-text-options.model';
import {Names} from './names';

export class DescriptionText extends BaseEntity {
  class = Names.DESCRIPTION_TEXT;
  createdBy: any;
  updatedBy: any;
  description: string;
  valid: boolean;
  fieldName: string;
  path: string[];

  constructor(options: IDescriptionTextOptions) {
    super(options);
    this.createdBy = options.createdBy || 0;
    this.updatedBy = options.updatedBy || 0;
    this.description = options.description || '';
    this.valid = true;
    this.fieldName = '';
    this.path = options.path || [];
  }

  isValid(): boolean {
    return true;
  }

  show() {
    this.hidden = !!this.isHidden;
  }
}
