import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebcallsService {

  constructor(private http: HttpClient) {
  }

  /**
   * Gets an object {} with all the data retrieved from the webcall.
   * @param webservice Object with the complete information of the web service call.
   * @param question Question which is the entrypoint/trigger of the webservice.
   */
  getWebServiceData(webservice, question) : Observable<any> {
    const body = {userGuardCode: question.value};
    const service = window.location.pathname + webservice.webserviceName;
    return this.http.post(service, body);
  }
}
