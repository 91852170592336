import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Group} from '../../../../models/group.model';
import {Category} from '../../../../models/category.model';
import {WebcallsService} from '../../../../services/webcalls.service';
import {ConditionService} from '../../../../services/condition.service';
import {SystemField} from '../../../../models/system-field.model';
import {BaseEntity} from '../../../../models/base-entity.model';
import {DynamicAnswer} from '../../../../models/dynamic-answer.model';
import {RadioButtonQuestion} from '../../../../models/radio-button-question.model';
import {SubmitService} from '../../../../services/submit.service';
import {QuestionBase} from '../../../../models/question-base.model';
import {LoopGroup} from '../../../../models/loop-group.model';
import {TextboxQuestion} from '../../../../models/textbox-question.model';
import {DescriptionText} from '../../../../models/description-text.model';
import {Files} from '../../../../models/files.model';
import {QuestionService} from '../../../../services/question.service';
import {LogService} from '../../../../services/log.service';
import {Form} from '../../../../models/form.model';
import {Flow} from '../../../../models/flow';
import {CategoryFile} from '../../../../models/category-file.model';
import {FlowCondition} from '../../../../models/flow-condition';

@Component({
  selector: 'app-form-runner',
  templateUrl: './form-runner.component.html',
  styleUrls: ['./form-runner.component.css']
})
export class FormRunnerComponent implements OnInit, AfterViewInit {

  @Input() jsonForm: Observable<any> = new Observable<any>();
  @Input() initialAnswers: Observable<any> = new Observable<any>();
  @Input() readOnly: boolean = false;
  @Input() language: string = 'en';
  @Input() logs = false;
  @Input() highlightedAnswers: Observable<any> = new Observable<any>();

  @Output() submitElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitFilesEmitter: EventEmitter<Set<CategoryFile>> = new EventEmitter<Set<CategoryFile>>();
  @Output() updateCategoryList: EventEmitter<Category[]> = new EventEmitter<Category[]>();
  @Output() categoryInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();


  flows: Flow[] = [];

  categoryList: Category[] = [];

  formRunner: Form | null = null;

  categoryName: any = null;

  currentCategoryIndex: any = null;

  flowLogs: boolean = false;

  submitSuccess: boolean = false;

  webcalls: any = null;

  constructor(
    private cdRef: ChangeDetectorRef,
    private questionService: QuestionService,
    private submitService: SubmitService,
    private conditionService: ConditionService,
    private webcallService: WebcallsService,
    private translate: TranslateService,
    private logger: LogService,
    @Inject(DOCUMENT) private document?: any) {

    this.submitSuccess = false;
    this.readOnly = false;
  }

  ngAfterViewInit() {
    this.translate.setDefaultLang('nl');
    this.translate.use(this.language);
  }

  ngOnInit() {
    if (this.jsonForm) {
      this.jsonForm.subscribe(
        form => {
          this.flows = form.flows;
          this.formRunner = this.questionService.createForm(form);
          this.generateFormWithReferences(this.formRunner);
          this.updateChildrenPathsWithReferences();
          this.updateDynamicReferencesQuestionsLabels();
          this.categoryList = this.formRunner.children.map(category => category);
          // Emit first time for non-flow forms
          this.updateCategoryList.emit(this.categoryList);
          this.flowLogs = false;

          if (this.flows[0] !== undefined) {
            this.showStartForm();
            this.currentCategoryIndex = this.getFirstCategoryIndexAvailable();
          } else {
            this.showAll();
            this.currentCategoryIndex = this.getFirstCategoryIndexAvailable();
          }

          this.submitSuccess = false;
          if (this.logs) {
          }
          this.webcalls = [];
          this.getWebcalls();
          const systemFieldValues: URLSearchParams = this.extractDataFromURI();
          this.fillSystemFieldValues(systemFieldValues);
          this.chooseAndLoadCssStyle(systemFieldValues);

          this.isFormValid(new TextboxQuestion({valid: true}, this.translate));
          if (this.initialAnswers) {
            this.fillFormFromAnswers();
          }
          this.highlightQuestions();
          this.updateDynamicQuestionLabels();
        });
    }
  }

  // Select CSS files to use
  chooseAndLoadCssStyle(params: URLSearchParams): string {
    let res: string | null;

    res = params.get('styles');
    if (res === '' || res === null) {
      res = params.get('organization');
    }

    if (res === null) {
      return '';
    }

    this.loadCssStyle(res + '.css');
    return res + '.css';
  }

  // Load CSS in webpage
  loadCssStyle(styleName: string): void {
    if (!styleName || styleName === '.css') {
      return;
    }
    const head = this.document.getElementsByTagName('head')[0];

    const style = this.document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = `assets/theme/styles/${styleName}`;

    head.appendChild(style);
  }

  highlightAnswers(paths: any): void {
    for (const path of paths) {
      const entity = this.findQuestionInCategoryChildrenReferences(path);
      this.highlightCategory(path);
      entity.hightlighted = true;
    }
  }

  highlightCategory(path: any): void {
    const categoriesName = path[0].trim();
    for (const category of this.categoryList) {
      if (category.name === categoriesName) {
        category.highlightCategory();
      }
    }
  }

  public nextPage(): void {
    this.currentCategoryIndex = this.getNextNonHiddenCategoryIndex();
    this.setCategoryName(this.currentCategoryIndex);
    if (this.logs) {
      if (!this.formRunner) {
        throw Error('Form Runner not found');
      }
      this.logger.info('Next button', this.currentCategoryIndex + ' ' + this.formRunner.children[this.currentCategoryIndex].name +
        '  [' + new Date().toLocaleString() + ']');
    }
    this.isFormValid(new TextboxQuestion({valid: true}, this.translate));
    this.cdRef.detectChanges();
  }

  public previousPage(): void {
    this.currentCategoryIndex = this.getPreviousNonHiddenCategoryIndex();
    this.setCategoryName(this.currentCategoryIndex);
    if (this.logs) {
      if (!this.formRunner) {
        throw Error('Form Runner not found');
      }
      this.logger.info('Previous button: ', this.currentCategoryIndex + ' ' + this.formRunner.children[this.currentCategoryIndex].name +
        '  [' + new Date().toLocaleString() + ']');
    }
    this.isFormValid(new TextboxQuestion({valid: true}, this.translate));
    this.cdRef.detectChanges();
  }

  public navigateToCategory(index: number): void {
    this.currentCategoryIndex = index;
    this.setCategoryName(index);
    this.isFormValid(new TextboxQuestion({valid: true}, this.translate));
    if (this.logs) {
      if (!this.formRunner) {
        throw Error('Form Runner not found');
      }
      this.logger.info('Navigation panel: ', index + ' ' + this.formRunner.children[index].name +
        '  [' + new Date().toLocaleString() + ']');
    }
  }

  public isValidForm(): boolean {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }
    return this.formRunner.valid;
  }

  public isValidPage(): boolean {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }
    return this.formRunner.children[this.currentCategoryIndex].valid;
  }

  public hasNext(): boolean {
    const currentIndex = this.currentCategoryIndex;
    const nextIndex = this.getNextNonHiddenCategoryIndex();
    return currentIndex !== nextIndex;
  }

  public hasPrevious(): boolean {
    const currentIndex = this.currentCategoryIndex;
    const previousIndex = this.getPreviousNonHiddenCategoryIndex();
    return currentIndex !== previousIndex;
  }

  public hideUI(): void {
    this.submitSuccess = true;
  }

  public showUI(): void {
    this.submitSuccess = false;
  }

  public submitForm(): void {
    if (!this.formRunner) {
      return;
    }
    const formAnswers = this.submitService.getFormAnswers(this.formRunner);
    const files: Set<CategoryFile> = this.submitService.getFormFiles(this.formRunner);
    this.logger.info(files);
    this.submitFilesEmitter.emit(files);
    this.submitElement.emit(formAnswers);
  }

  public submitFiles(): void {
    if (!this.formRunner) {
      return;
    }
    const files: Set<CategoryFile> = this.submitService.getFormFiles(this.formRunner);
    if (files.size > 0) {
      this.submitFilesEmitter.emit(files);
    }
  }

  public updateCategory(): void {
    this.updateCategoryList.emit(this.categoryList);
  }

  public getJsonForm(): any {
    return [this.formRunner, this.currentCategoryIndex];
  }

  fillSystemFieldValues(urlParams: URLSearchParams): void {
    urlParams.forEach((value, key) => {
      this.findAndUpdateSystemField(value, key);
    });
  }

  findAndUpdateSystemField(systemFieldName: string, value: any): void {
    if (!this.formRunner) {
      return;
    }
    for (const formChildren of this.formRunner.children) {
      for (const questionFound of formChildren.childrenPaths) {
        // If this gives errors put a system field instanceof
        if ((questionFound instanceof SystemField) && questionFound.name !== undefined && questionFound.name === systemFieldName) {
          questionFound.value = value;
          this.updateAnswer([questionFound, true]);
          return;
        }
      }
    }
  }

  extractDataFromURI(): URLSearchParams {
    return new URL(location.href).searchParams;
  }

  /**
   * Return the first non-hidden category index.
   */
  getFirstCategoryIndexAvailable(): number {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }
    for (let i = 0; i < this.formRunner.children.length; i++) {
      if (this.formRunner.children[i].hidden === false && !this.formRunner.children[i].getIsHidden()) {
        return i;
      }
    }
    throw Error('All categories are hidden');
  }

  /**
   * Return the category with that name.
   * @param category Name of the category.
   */
  findCategory(category: string): number {
    if (!this.formRunner) {
      return -1;
    }
    for (let i = 0; i < this.formRunner.children.length; i++) {
      if (this.formRunner.children[i].name === category) {
        return i;
      }
    }
    return -1;
  }

  setCategoryName(index: number): void {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }
    this.categoryName = this.formRunner.children[index].label;
  }

  getPreviousNonHiddenCategoryIndex(): number {
    if (!this.formRunner) {
      return this.currentCategoryIndex;
    }
    for (let i = this.currentCategoryIndex - 1; i >= 0; i--) {
      if (this.formRunner.children[i].hidden === false && !this.formRunner.children[i].getIsHidden()) {
        return i;
      }
    }
    return this.currentCategoryIndex;
  }

  getNextNonHiddenCategoryIndex(): number {
    if (!this.formRunner) {
      return this.currentCategoryIndex;
    }
    for (let i = this.currentCategoryIndex + 1; i <= this.formRunner.children.length - 1; i++) {
      if (this.formRunner.children[i].hidden === false && !this.formRunner.children[i].getIsHidden()) {
        return i;
      }
    }
    return this.currentCategoryIndex;
  }

  onSubmit() {
    if (this.logs) {
      this.logger.info('Submit button pressed.: ', this.formRunner + '  [' + new Date().toLocaleString() + ']');
    }
    const formAnswers = this.submitService.getFormAnswers(this.formRunner);
    this.logger.info(formAnswers);
    this.showSubmitSuccess();
  }

  showSubmitSuccess() {

  }

  isFormValid(question: any): void {
    let isValid = false;
    // let question = event
    if (question.valid === true) {
      if (!this.formRunner) {
        return;
      }
      this.isCategoryValid(this.currentCategoryIndex);
      isValid = this.formRunner.isValid();
      if (!isValid) {
        this.isFormInvalid.emit(true);
        return;
      }
      if (this.currentCategoryIndex === this.getNextNonHiddenCategoryIndex()) {
        this.isFormInvalid.emit(false); // only enable submit button if you are in the last category
      } else {
        this.isFormInvalid.emit(true);
      }
    } else {
      this.isCategoryValid(this.currentCategoryIndex);
      this.isFormInvalid.emit(true);
      return;
    }
    // this.cdRef.detectChanges()
  }

  isCategoryValid(categoryIndex: number): boolean {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }

    let isValid: boolean;
    isValid = this.formRunner.children[categoryIndex].isValid();
    if (!isValid) {
      this.categoryInvalid.emit(true);
      return false;
    }

    const nextInvalid = this.currentCategoryIndex + 1 === this.formRunner.children.length
      || this.getNextNonHiddenCategoryIndex() === this.currentCategoryIndex;
    if (nextInvalid) {
      this.categoryInvalid.emit(true);
      return false;
    } else {
      this.categoryInvalid.emit(false);
      return true;
    }

  }

  isGroupValid(group: Group): boolean {
    let isValid: boolean;
    isValid = group.isValid();
    if (!isValid) {
      this.isFormInvalid.emit(true);
      return false;
    }
    return true;
  }

  createLoopGroup(event: LoopGroup): void {
    if (this.logs) {
      this.logger.info('Create loop group: ', event.name, '  [' + new Date().toLocaleString() + ']');
    }
    this.isCategoryValid(this.currentCategoryIndex);
  }

  deleteLoopGroup(event: LoopGroup) {
    if (this.logs) {
      this.logger.info('Delete loop group: ', event.name, '  [' + new Date().toLocaleString() + ']');
    }
    this.isCategoryValid(this.currentCategoryIndex);
  }

  fulfillsEndForm(question: any): boolean {
    for (const flow of this.flows) {
      if (flow.flowType === 'END_FORM') {
        if (flow.condition !== undefined && flow.condition.length === 0) {
          if (flow.originId.length === question.path.length) {
            if (this.comparePath(question.path, flow.originId)) {
              if (this.flowLogs) {
                this.logger.info('Correct end form', question.name);
              }
              // this.buttonNextDisabled = true;
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  fulfillsOthers(question: any): any {
    if (question.value !== undefined || question instanceof DescriptionText) {
      for (const flow of this.flows) {
        if (flow.others === true) {
          if (flow.originId.length === question.path.length) {
            const equal = true;
            if (this.comparePath(question.path, flow.originId)) {
              if (this.flowLogs) {
                this.logger.info('Correct others', question.name);
              }
              if (flow.flowType === 'END_FORM') {
                if (this.flowLogs) {
                  this.logger.info('Correct END_FORM others(stop)', question.name);
                  return [equal, 'destinyPath', true];
                }
              } else {
                const destinyPath = flow.destinyId;
                return [equal, destinyPath, false];
              }
            }
          }
        }
      }
    }
    return false;
  }

  fulfillsSimpleJump(question: any): any {
    for (const flow of this.flows) {
      if (flow.condition !== undefined && flow.condition.length === 0) {
        if (flow.originId.length === question.path.length) {
          const equal = true;
          if (this.comparePath(question.path, flow.originId)) {
            const destinationCategoryId = flow.destinyId[0];
            const destinations = flow.destinyId[flow.destinyId.length - 1];
            if (this.flowLogs) {
              this.logger.info('Correct simple jump a ', destinationCategoryId, destinations);
            }
            const destinyPath = flow.destinyId;
            return [equal, destinyPath];
          }
        }
      }
    }
    return false;
  }

  comparePath(path: any, path2: any): boolean {
    if (!path || !path2 || path.length !== path2.length) {
      return false;
    } else {
      for (let i = 0; i < path.length; i++) {
        if (path[i] !== path2[i]) {
          return false;
        }
      }
      return true;
    }
  }

  hideAllFromThisPoint(question: any): void {
    if (!this.formRunner || !question) {
      return;
    }
    let found = false;
    const questionPath = question.path;
    for (const category of this.formRunner.children) {
      if (found) {
        category.hidden = true;
      }
      for (const child of category.children) {
        if (child instanceof Group) {
          const group = child as Group;
          if (found) {
            this.hideGroup(group);
          } else {
            found = this.digAndHideGroup(question, group);
          }
        } else {
          // question
          const questionToHide = child as BaseEntity;
          if (found) {
            questionToHide.hidden = true;
          }
          if (questionToHide instanceof SystemField) {
            questionToHide.hidden = false;
            if (this.comparePath(questionPath, questionToHide.path)) {
              found = true;
            }
          }
          if (questionToHide.class !== 'com.biit.webforms.persistence.entity.SystemField') {
            if (this.comparePath(questionPath, questionToHide.path)) {
              found = true;
            }
          }

        }
      }
    }
  }

  hideGroup(group: Group): void {
    group.hidden = true;
    for (const child of group.children) {
      if (child instanceof Group) {
        this.hideGroup(child as Group);
      } else {
        child.hidden = !(child instanceof SystemField);
      }
    }
  }

  digAndHideGroup(question: any, group: Group): boolean {
    let found = false;
    const questionPath = question.path;
    for (const entity of group.children) {
      if (entity instanceof Group) {
        if (found) {
          this.hideGroup(entity);
        } else {
          found = this.digAndHideGroup(question, entity);
        }
      } else {
        // question
        if (found) {
          entity.hidden = !(entity instanceof SystemField);
        }
        const ent: QuestionBase<string> = entity as QuestionBase<string>;
        if (this.comparePath(questionPath, ent.path)) {
          found = true;
        }
      }
    }
    return found;
  }

  getFirstEntityWithFlow(): BaseEntity | null {
    if (!this.formRunner) {
      throw Error('Form Runner not found');
    }
    for (const category of this.formRunner.children) {
      for (const entity of category.children) {
        if (entity instanceof Group) {
          const possibleObject = this.findInGroup(entity);
          if (possibleObject) {
            return possibleObject;
          }
        } else {
          if (this.questionWithFlow(entity)) {
            return entity;
          }
        }
      }
    }
    return null;
  }

  findInGroup(group: Group): BaseEntity | null {
    for (const child of group.children) {
      if (child instanceof Group) {
        const possibleObject = this.findInGroup(child);
        if (possibleObject) {
          return possibleObject;
        }
      } else {
        if (this.questionWithFlow(child)) {
          return child;
        }
      }
    }
    return null;
  }

  findQuestionInCategoryChildrenReferences(path: any): any {
    if (!path || !path.length) {
      return null;
    }
    if (!this.formRunner) {
      return;
    }
    const originCategoryId = path[0];
    for (const category of this.formRunner.children) {
      if (category.name === originCategoryId) {
        for (const entity of category.childrenPaths) {
          if (this.comparePath(entity.path, path)) {
            return entity;
          }
        }
      }
    }
    throw new Error('Path not found:' + path);
  }

  triggersAWebService(question: any): any {
    if (!this.formRunner) {
      return undefined;
    }
    if (this.formRunner.webserviceCalls.length > 0) {
      if (question.isValid()) {
        for (const webService of this.formRunner.webserviceCalls) {
          if (this.comparePath(webService.formElementTrigger_id, question.path)) {
            return webService;
          }
        }
        return undefined;
      }
    }
    return undefined;
  }

  getMonth(month: number): string {
    const newMonth = month + 1;
    const div = newMonth / 10;
    // if just has one digit add a 0
    if (div < 1) {
      return '0' + newMonth;
    }
    return newMonth.toString();
  }

  getDay(day: number): string {
    const newDay = day;
    const div = newDay / 10;
    // if just has one digit add a 0
    if (div < 1) {
      return '0' + newDay;
    }
    return newDay.toString();
  }

  findValueInData(data: any, value: any): any {
    if (data[value] !== undefined) {
      if (value === 'birthdate') {
        const date = new Date(data[value]);
        return date.getFullYear() + '-' + this.getMonth(date.getMonth()) + '-' + this.getDay(date.getDate());
      }
      return data[value];
    } else {
      return undefined;
    }
  }

  parseValueInData(data: any, value: any): string | undefined {
    if (data[value]) {
      let res = '';
      const val: string = data[value].toLowerCase(); // use only lower case to values in webforms
      res += val.toLowerCase().trim();
      return res;
    } else {
      return undefined;
    }
  }

  fillUserData(data: Observable<any>, outputLinks: any, webcall: any): void {
    data.subscribe(
      values => {
        if (values.error === undefined && values.code !== 'ENOTFOUND') {
          for (const output of outputLinks) {
            const question = this.findFlowQuestion(output.formElement_id);
            if (question !== undefined) {
              if (question instanceof RadioButtonQuestion) {
                question.value = this.parseValueInData(values, output.webservicePort);
                question.realValue = question.value;
              } else {
                question.value = this.findValueInData(values, output.webservicePort);
              }
              question.isEditable = output.isEditable;
            }
          }
          webcall.correct = true;
          this.cdRef.detectChanges();
        }
      },
      error => {
        this.logger.info(error);
      }
    );
    this.cdRef.detectChanges();
  }

  updateAnswer(question: any): void {
    this.isFormValid(question[0]);
    const webService = this.triggersAWebService(question[0]);
    if (webService !== undefined) {
      if (question[0].value !== '' && question[0].value !== undefined) {
        const webcall = this.findWebCall(webService.webserviceName);
        if (webcall !== null && webcall.correct === false) {
          const triggerQuestion = this.findFlowQuestion(webService.inputLinks[0].formElement_id);
          const data: Observable<any> = this.webcallService.getWebServiceData(webService, triggerQuestion);
          this.fillUserData(data, webService.outputLinks, webcall);
        }
      }
    }
    // if (question[0].valid && question[1]) {
    if (question[1]) {
      this.checkFlow(question[0]);
    }
    this.isCategoryValid(this.currentCategoryIndex);
  }

  updateDynamicAnswersLabels(): void {
    if (!this.formRunner) {
      return;
    }
    for (const category of this.formRunner.children) {
      for (const question of category.childrenPaths) {
        for (const answer of question.children) {
          if (answer instanceof DynamicAnswer) {
            answer.label = answer.reference.value;
          }
        }
      }
    }
  }

  updateDynamicQuestionLabels(): void {
    if (!this.formRunner) {
      return;
    }
    for (const category of this.formRunner.children) {
      if (category.hasDynamicLabel) {
        category.setUpdatedLabel();
        this.updateCategory();
      }
      for (const entity of category.childrenPaths) {
        entity.setUpdatedLabel();
      }
    }
  }

  checkFlow(question: BaseEntity): void {
    const entityPathWithFlowOnCondition = this.questionWithFlowInCondition(question);
    if (this.questionWithFlow(question)) {
      this.hideAllFromThisPoint(question);
      const firstQuestionWithFlow = this.getFirstEntityWithFlow();
      this.isFlowFulfilled(firstQuestionWithFlow);
      this.updateCategory();
      this.cdRef.detectChanges();
    } else if (entityPathWithFlowOnCondition) {
      // Question that is not the origin of a flow but appears in the condition of another
      // Hide from the origin_id of that flow object
      const hideFromHere = this.findQuestionInCategoryChildrenReferences(entityPathWithFlowOnCondition);
      this.hideAllFromThisPoint(hideFromHere);
      const firstQuestionWithFlow = this.getFirstEntityWithFlow();
      this.isFlowFulfilled(firstQuestionWithFlow);
      this.updateCategory();
      this.cdRef.detectChanges();
    }
    this.updateDynamicAnswersLabels();
    this.updateDynamicQuestionLabels();
    this.isFormValid(question);
    this.isCategoryValid(this.currentCategoryIndex);

  }

  // Shows the question with the provided destinyPath
  nextQuestion(destinyPath: any): void {
    if (!this.formRunner) {
      return;
    }
    let found = false;
    for (const category of this.formRunner.children) {
      for (const entity of category.childrenPaths) {
        const objectPath = entity.path;
        if (this.comparePath(destinyPath, objectPath) || found) {
          found = true;
          this.showEntityAndParents(entity);
          if (this.questionWithFlow(entity)) {
            this.isFlowFulfilled(entity);
            return;
          }
        }
      }
    }
  }

  questionWithFlow(question: any): boolean {
    for (const flow of this.flows) {
      if (this.comparePath(question.path, flow.originId)) {
        if (this.flowLogs) {
          this.logger.info('Has flow:', question.name);
        }
        return true;
      }
    }
    return false;
  }

  questionWithFlowInCondition(question: any): string[] {
    for (const flow of this.flows) {
      if (flow.condition !== undefined && flow.condition.length > 0) {
        for (const predicate of flow.condition) {
          if (predicate.question_id !== undefined) {
            if (this.comparePath(question.path, predicate.question_id)) {
              return flow.originId;
            }
          }
        }
      }
    }
  }

  isFlowFulfilled(question: any): boolean {

    const conditionFilled = this.fulfillsFlowWithCondition(question);
    if (conditionFilled[0]) {
      return true;
    }
    const othersFilled = this.fulfillsOthers(question);
    if (othersFilled[0]) {
      if (othersFilled[2]) {
        return true;
      } else {
        const destinyPath = othersFilled[1];
        this.nextQuestion(destinyPath);
        this.cdRef.detectChanges();
        return true;
      }
    }
    if (this.fulfillsEndForm(question)) {
      return true;
    }
    const simpleJumpFilled = this.fulfillsSimpleJump(question);
    if (simpleJumpFilled[0]) {
      const destinyPath = simpleJumpFilled[1];
      this.nextQuestion(destinyPath);
      this.cdRef.detectChanges();
      return true;
    }
    if (this.flowLogs) {
      this.logger.error('Has flow and is not correct(stop):', question.name);
    }
    return false;
  }

  fulfillsFlowWithCondition(question: any): [boolean] {
    let cond = false;
    for (const flow of this.flows) {
      if (question && flow.condition && flow.condition.length > 0) {
        if (this.comparePath(question.path, flow.originId) && this.formRunner) {
          const condition = this.conditionService.checkCondition(this.formRunner, question, flow);
          if (condition) {
            if (flow.flowType === 'END_FORM') {
              if (this.flowLogs) {
                this.logger.info('Meets END_FORM condition for answer(stop): ', question.value);
              }
              return [true];
            } else {
              if (this.flowLogs) {
                if (question instanceof DescriptionText) {
                  this.logger.info('Meets condition for descriptionText: ', question.name);
                } else {
                  this.logger.info(question.name, 'Meets condition for answer: ', question.value);
                }
              }
              const destinyPath = flow.destinyId;
              this.nextQuestion(destinyPath);
              this.cdRef.detectChanges();
              cond = true;
            }
          }
        }
      }
    }
    return [cond];
  }

  generateFlow(flows: Flow[]): Flow[] {
    const flowNavigation: Flow[] = JSON.parse(JSON.stringify(this.flows));
    for (let i = 0; i < flows.length; i++) {
      // Add reference to origin
      flowNavigation[i].originId = this.findFlowQuestion(flows[i].originId);
      if (flows[i].destinyId !== undefined) {
        // Add reference to destiny
        flowNavigation[i].destinyId = this.findFlowQuestion(flows[i].destinyId);
      }
      if (flows[i].condition !== undefined && flows[i].condition.length > 0) {
        const conditions: FlowCondition[] = flowNavigation[i].condition;
        for (const condition of conditions) {
          if (condition.question_id !== undefined) {
            condition.question_id = this.findFlowQuestion(condition.question_id);
          }
        }
      }
    }
    return flowNavigation;
  }

  findFlowQuestion(question: any): any {
    if (!this.formRunner) {
      return;
    }
    const category = question[0];
    const groups = question.slice(1, question.length - 1);
    const questionKey = question[question.length - 1];
    const catIndex = this.findCategory(category);

    if (groups.length === 0) {
      for (const child of this.formRunner.children[catIndex].children) {
        if (child.name === questionKey) {
          return child;
        }
      }
    } else {
      const groupName = groups[0];
      for (const child of this.formRunner.children[catIndex].children) {
        if (child.name === groupName) {
          return this.findFlowGroup(child, groups, questionKey);
        }
      }
    }

  }

  findFlowGroup(group: any, remainingGroups: any, questionKey: any): any {
    const remanent = remainingGroups.slice(1, remainingGroups.length);
    if (remanent.length === 0) {
      for (const child of group.children) {
        if (child.name === questionKey) {
          return child;
        }
      }
    } else {
      const groupToFind = remanent[0];
      for (const child of group.children) {
        if (child.name === groupToFind) {
          return this.findFlowGroup(child, remanent, questionKey);
        }
      }

    }
  }

  showAll(): void {
    if (!this.formRunner) {
      return;
    }
    this.formRunner.show();
  }

  showQuestion(question: any): void {
    question.hidden = false;
  }

  showStartForm(): void {
    if (!this.formRunner) {
      return;
    }
    this.currentCategoryIndex = 0;
    for (const category of this.formRunner.children) {
      if (!category.isHidden) {
        category.hidden = false;
        for (const entity of category.children) {
          if (!entity.isHidden) {
            if (entity instanceof Group) {
              if (this.checkFlowFromGroup(entity)) {
                return;
              } else {
                entity.show();
              }
            } else if (entity instanceof Files) {
              if (this.checkFlowFromQuestion(entity)) {
                this.checkFlow(entity);
                return;
              } else {
                entity.show();
              }
            } else {
              if (this.checkFlowFromQuestion(entity)) {
                this.checkFlow(entity as unknown as QuestionBase<string>);
                return;
              } else {
                entity.show();
              }
            }
          }
        }
      }
    }
  }

  checkFlowFromQuestion(question: any): boolean {
    this.showQuestion(question);
    for (const flow of this.flows) {
      if (this.comparePath(question.path, flow.originId)) {
        this.showQuestion(question);
        this.checkFlow(question);
        return true;
      }
    }
    return false;
  }

  checkFlowFromGroup(group: Group): any {
    group.hidden = false;
    for (const child of group.children) {
      if (child instanceof Group) {
        if (this.checkFlowFromGroup(child as Group)) {
          return true;
        }
      } else {
        if (this.checkFlowFromQuestion(child)) {
          return true;
        }
      }
    }
    return false;
  }

  showEntityAndParents(entity: any): void {
    entity.hidden = false;
    if (entity.parent !== undefined) {
      this.showEntityAndParents(entity.parent);
    }
  }

  /**
   * Update the parent property with a reference to its parent object
   * to make navigation easier.
   */
  generateFormWithReferences(form: any): void {
    for (const category of form.children) {
      category.parent = form;
      for (const entity of category.children) {
        entity.parent = category;
        if (entity instanceof Group) {
          this.generateReferencesOnGroup(entity);
        }
      }
    }
  }

  generateReferencesOnGroup(group: any) {
    for (const entity of group.children) {
      entity.parent = group;
      if (entity instanceof Group) {
        this.generateReferencesOnGroup(entity);
      }
    }
  }

  findCategoryByName(categoryName: string): Category | null {
    if (!this.formRunner) {
      return null;
    }
    for (const category of this.formRunner.children) {
      if (category.name === categoryName) {
        return category;
      }
    }
    return null;
  }

  /**
   * Change the childrenPath array property with actual references to the entities objects.
   */
  updateChildrenPathsWithReferences() {
    if (!this.formRunner) {
      return;
    }
    for (const category of this.formRunner.children) {
      for (let j = 0; j < category.childrenPaths.length; j++) {
        const path = category.childrenPaths[j];
        const entityReference = this.findFlowQuestion(path);
        if (entityReference instanceof Files) {
          entityReference.setCategory();
        }
        category.childrenPaths[j] = entityReference;
        this.updateDynamicAnswersReferences(category.childrenPaths[j]);
      }
    }
  }

  updateDynamicAnswersReferences(question: any) {
    for (const answer of question.children) {
      if (answer instanceof DynamicAnswer) {
        answer.reference = this.findFlowQuestion(answer.reference);
      }
    }
  }

  getWebcalls(): void {
    if (!this.formRunner) {
      return;
    }
    for (const webcall of this.formRunner.webserviceCalls) {
      this.webcalls.push({call: webcall, correct: false});
    }
  }

  findWebCall(name: string) {
    for (const webcall of this.webcalls) {
      if (webcall.call.webserviceName === name) {
        return webcall;
      }
    }
    return new Error('Webcall not found');
  }

  updateDynamicReferencesQuestionsLabels(): void {
    if (!this.formRunner) {
      return;
    }
    for (const category of this.formRunner.children) {
      category.setParsedDynamicLabelWithReferences();
      for (const entity of category.childrenPaths) {
        if (entity instanceof QuestionBase && entity.hasDynamicLabel) {
          entity.setParsedDynamicLabelWithReferences();
        }
        this.updateDynamicReferencesParent(entity);
      }
    }
  }

  updateDynamicReferencesParent(entity: BaseEntity) {
    if (entity.parent) {
      entity.setParsedDynamicLabelWithReferences();
      this.updateDynamicReferencesParent(entity.parent);
    }
  }

  private fillFormFromAnswers() {
    this.initialAnswers.subscribe((jsonAnswers) => {
      this.fillNodeFromAnswersNode(this.formRunner, jsonAnswers);
      this.isFormValid(new TextboxQuestion({valid: true}, this.translate));
      this.cdRef.detectChanges();
    });
  }

  private highlightQuestions() {
    if (this.highlightedAnswers) {
      this.highlightedAnswers.subscribe(
        resp => {
          if (resp) {
            const questionPaths = resp.data;
            const parsedPaths = this.parsePaths(questionPaths);
            this.highlightAnswers(parsedPaths);
          }
        }, error => {
          this.logger.info(error);
        }
      );
    }
  }

  private parsePaths(questionPaths: any): string[] {
    if (questionPaths) {
      const parsedPaths: string[] = [];
      const unparsedPaths = questionPaths.split(',');
      for (const unparsedPath of unparsedPaths) {
        const parsedPath = unparsedPath.trim().split('/');
        parsedPaths.push(parsedPath);
      }
      return parsedPaths;
    }
    return [];
  }

  private fillNodeFromAnswersNode(form: any, answers: any): void {
    if (form && answers && form.children && answers.children) {
      for (const formChildren of form.children) {
        for (const answerChildren of answers.children) {
          if (formChildren.name === answerChildren.name) {
            formChildren.hidden = false;
            if (answerChildren.children && answerChildren.children.length > 0) {
              this.fillNodeFromAnswersNode(formChildren, answerChildren);
            } else {
              if (answerChildren.class === 'com.biit.form.result.QuestionWithValueResult' && answerChildren.values.length > 0) {
                if (formChildren.controlType === 'multicheck' || formChildren.controlType === 'radiobutton') {
                  formChildren.value = answerChildren.values[0];
                  for (const checkbox of formChildren.children) {
                    if (answerChildren.values.indexOf(checkbox.name) >= 0) {
                      checkbox.checked = true;
                    }
                  }
                } else {
                  formChildren.value = answerChildren.values[0];
                }
              }
            }
          }
        }
      }
    }
  }

}
