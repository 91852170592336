import {Group} from './group.model';
import {IGroupOptions} from './interfaces/igroup-options.model';

export class LoopGroup extends Group {
  repeatable = true;
  valid = false;

  constructor(options: IGroupOptions) {
    super(options);
  }
}
