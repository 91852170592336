import {ICategoryOptions} from './interfaces/icategory-options.model';
import {BaseEntity} from './base-entity.model';

export class Category extends BaseEntity {
  createdBy: number;
  updatedBy: number;
  childrenPaths: any;
  valid = false;
  cssClass = 'fr-element navCategoryName';

  constructor(options: ICategoryOptions) {
    super(options);
    this.createdBy = options.createdBy || 0;
    this.updatedBy = options.updatedBy || 0;
    this.childrenPaths = options.childrenPaths || [];
  }

  highlightCategory(): void {
    this.cssClass = 'fr-element navCategoryName highlighted';
  }

  markAsInvalid(): void {
    this.cssClass = 'fr-element navCategoryName invalid';
  }

  markAsValid(): void {
    this.cssClass = 'fr-element navCategoryName';
  }
}
