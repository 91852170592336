import {Injectable} from '@angular/core';
import {Group} from '../models/group.model';
import {BaseEntity} from '../models/base-entity.model';
import {SystemField} from '../models/system-field.model';
import {Names} from '../models/names';
import {QuestionBase} from '../models/question-base.model';
import {Files} from '../models/files.model';
import {DescriptionText} from '../models/description-text.model';
import {LogService} from './log.service';
import {Header} from '../models/header';
import {Form} from '../models/form.model';
import {CategoryFile} from '../models/category-file.model';

@Injectable({
  providedIn: 'root'
})
export class SubmitService {

  public static FORM_RESULT: string = Names.FORM_RESULT;
  public static CATEGORY_RESULT: string = Names.CATEGORY_RESULT;
  public static GROUP_RESULT: string = Names.GROUP_RESULT;
  public static QUESTION_RESULT: string = Names.QUESTION_RESULT;

  public static SYSTEM_FIELD_RESULT: string = Names.SYSTEM_FIELD_RESULT;

  constructor(private logger: LogService) {
  }


  /**
   * Return an object with the answers of the form.
   * @param form Form.
   */

  public getFormFiles(form: Form) {
    let files: Set<CategoryFile> = new Set();
    files = this.getFiles(form, files);
    return files;

  }

  getFiles(entity: BaseEntity, files: Set<CategoryFile>) {
    if (entity instanceof Files) {
      if (entity.isShown()) {
        files = this.addToSet(entity.files, files, entity.getCategoryLabel());
      }
    }
    if (entity.children.length !== 0) {
      for (const entityChildren of entity.children) {
        files = this.getFiles(entityChildren, files);
      }
    }
    return files;
  }

  addToSet(files: Set<File>, set: Set<CategoryFile>, category) {
    files.forEach(file => {
      set.add({file, category});
    });
    return set;
  }

  public getFormAnswers(form) {
    return this.createJsonHeaderForm(form);
  }

  createJsonHeaderForm(formHeader) {
    return {
      class: SubmitService.FORM_RESULT,
      comparationId: formHeader.comparationId,
      creationTime: formHeader.creationTime,
      updateTime: formHeader.updateTime,
      name: formHeader.name,
      label: formHeader.label,
      updatedBy: formHeader.updatedBy,
      version: formHeader.version,
      organizationId: formHeader.organizationId,
      children: this.getFormChildren(formHeader)
    };
  }

  getFormChildren(form: Form): any[] {
    const answers: any[] = [];
    for (const child of form.children) {
      if (child.hidden === false && !child.getIsHidden()) {
        const categoryHeader = this.createCategoryHeaderForm(child);
        categoryHeader[Header.CHILDREN] = this.getCategoryChildren(child.children);
        answers.push(categoryHeader);
      }
    }

    return answers;
  }

  createCategoryHeaderForm(category) {
    const categoryHeader = {};
    categoryHeader[Header.CLASS] = SubmitService.CATEGORY_RESULT;
    categoryHeader[Header.COMPARATION] = category.comparationId;
    categoryHeader[Header.CREATION_TIME] = category.creationTime;
    categoryHeader[Header.UPDATE_TIME] = category.updateTime;
    categoryHeader[Header.NAME] = category.name;
    categoryHeader[Header.LABEL] = category.label;
    return categoryHeader;
  }

  getCategoryChildren(questions): any[] {
    const answers:any[] = [];
    for (const question of questions) {
      if (question.hidden === false && !question.getIsHidden()) {
        if (question instanceof SystemField) {
          const systemField = this.createSystemFieldHeader(question);
          answers.push(systemField);
        } else if (question instanceof Group) {
          const groupHeader = this.createGroupHeader(question);
          groupHeader[Header.CHILDREN] = this.getGroupChildren(question.children);
          answers.push(groupHeader);
        } else {
          const questionHeader = this.createQuestionResultHeader(question);
          answers.push(questionHeader);
        }
      }
    }
    return answers;
  }

  getGroupChildren(groups) {
    const answers: any[] = [];
    for (const group of groups) {
      if (group.hidden === false && !group.getIsHidden()) {
        if (group instanceof Group) {
          const groupHeader = this.createGroupHeader(group);
          groupHeader[Header.CHILDREN] = this.getGroupChildren(group.children);
          answers.push(groupHeader);
        } else {
          const questionHeader = this.createQuestionResultHeader(group);
          answers.push(questionHeader);
        }
      }
    }
    return answers;
  }

  createQuestionResultHeader(question) {
    const questionHeader = {};
    questionHeader[Header.CLASS] = SubmitService.QUESTION_RESULT;
    questionHeader[Header.COMPARATION] = question.comparationId;
    questionHeader[Header.CREATION_TIME] = question.creationTime;
    questionHeader[Header.UPDATE_TIME] = question.updateTime;
    questionHeader[Header.NAME] = question.name;
    if (question instanceof DescriptionText) {
      questionHeader[Header.LABEL] = question.description;
    } else {
      questionHeader[Header.LABEL] = question.label;
    }
    questionHeader[Header.CHILDREN] = [];
    if (question.controlType === 'multicheck') {
      questionHeader[Header.VALUES] = this.getCheckBoxValues(question);
    } else if (question.controlType === 'radiobutton') {
      questionHeader[Header.VALUES] = [
        question.realValue
      ];
    } else {
      questionHeader[Header.VALUES] = [
        question.value
      ];
      if (question instanceof Files) {
        questionHeader[Header.VALUES] = this.getFilesNames(question);
      }
    }
    if (question instanceof QuestionBase) {
      questionHeader[Header.ANSWER_LABELS] = question.getAnswerLabel();
    }
    if (question instanceof Files) {
      questionHeader[Header.ANSWER_LABELS] = this.getFilesNames(question);
    }
    return questionHeader;
  }

  createGroupHeader(group: any) {
    const groupHeader: any = {};
    groupHeader[Header.CLASS] = SubmitService.GROUP_RESULT;
    groupHeader[Header.COMPARATION] = group.comparationId;
    groupHeader[Header.CREATION_TIME] = group.creationTime;
    groupHeader[Header.UPDATE_TIME] = group.updateTime;
    groupHeader[Header.NAME] = group.name;
    groupHeader[Header.LABEL] = group.label;
    groupHeader[Header.REPEATABLE] = group.repeatable;
    return groupHeader;
  }

  createSystemFieldHeader(system: any) {
    const systemHeader: any = {};
    systemHeader[Header.CLASS] = SubmitService.SYSTEM_FIELD_RESULT;
    systemHeader[Header.COMPARATION] = system.comparationId;
    systemHeader[Header.CREATION_TIME] = system.creationTime;
    systemHeader[Header.UPDATE_TIME] = system.updateTime;
    systemHeader[Header.NAME] = system.name;
    systemHeader[Header.LABEL] = system.label;
    systemHeader[Header.VALUES] = [system.value];
    systemHeader[Header.FIELD_NAME] = system.fieldName;
    return systemHeader;
  }

  getCheckBoxValues(question: any) {
    const values: any[] = [];
    for (const questionValue of question.value) {
      if (questionValue.checked === true) {
        if (questionValue.children.length === 0) {
          values.push(questionValue.name);
        } else {
          for (const children of questionValue.children) {
            if (children.checked === true) {
              values.push(children.name);
            }
          }
        }
      }
    }
    return values;
  }

  getFilesNames(files: Files) {
    const values: string[] = [];
    files.getFiles().forEach(file => {
      values.push(file.name);
    });
    this.logger.info(values);
    return values;
  }

}
