import {QuestionBase} from './question-base.model';
import {ITextAreaOptions} from './interfaces/itext-area-options.model';
import {Names} from './names';

export class TextAreaQuestion extends QuestionBase<string> {
  controlType = 'textarea';
  cssTag = 'fr-answer textarea';

  constructor(options: ITextAreaOptions) {
    // {} = {}
    super(options);
  }

  setValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  setInitialAnswers(question: any): void {
    if (question.hasOwnProperty('defaultValueString')) {
      this.value = question.defaultValueString;
    }
  }

  setCssClass(): void {
    if (this.isValid()) {
      this.cssTag = Names.CSS_TEXTAREA_QUESTION_VALID;
    } else {
      this.cssTag = Names.CSS_TEXTAREA_QUESTION_INVALID;
    }
  }

  isValid(): boolean {
    if (this.required) {
      return !(this.value === undefined || this.value === '');
    } else {
      return true;
    }
  }

  getAnswerLabel(): any[] {
    return [this.value];
  }
}

