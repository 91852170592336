import {BaseEntity} from './base-entity.model';
import {IAnswerOptions} from './interfaces/ianswer-options.model';

export class Answer extends BaseEntity {
  children: Answer[];

  constructor(options: IAnswerOptions) {
    super(options);
    this.children = options.children || [];
  }
}
